@import "variables.scss";
.copy-clipboard-icon {
  padding-left:10px;
}

.no-select{
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.justify-end {
  justify-content: flex-end;
}


.Teacher,
.School,
.District {
  .ui.header {
    font-weight: normal !important;
  }

  .commonHeading.ui.header {
    color: $teacher-primary;
    font-family: $app-medium-font;
    font-size: 34px;
    line-height: 42px;
    margin: 16px 0 0 14px;
  }

  .libraryMenu.ui.list {
    display: flex;
    align-items: center;
    // padding: 7px 0 0 !important;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 991;
    margin: 0 0px 15px;
    padding: 0 0 20px;
    &::after {
      content: "";
      position: absolute;
      left: -62px;
      top: 0;
      bottom: 0;
      width: 62px;
      background-color: #fff;
    }
    a.item {
      font-size: 13px;
      font-family: $app-primary-medium-font;
      margin-left: 38px;
      position: relative;
      color: #003e52;
      font-weight: 500;
      letter-spacing: 0.38px;
      line-height: 15px;
      padding: 23px 0px 0;

      &:hover,
      &.active {
        color: $teacher-secondary-color;

        &::before {
          opacity: 1;
        }
      }

      &::before {
        content: "";
        position: absolute;
        // top: 0;
        top: 0;
        left: 0;
        right: 0;
        background-color: $teacher-secondary-color;
        height: 2.5px;
        opacity: 0;
      }
    }

    &.bookDetailBreadCrumb {
      // position: relative;
      // top: -7px;

      .secondCrumb {
        position: relative;
        left: 23px;
        top: -3.3px;
      }
    }
  }

  // .aleeLibrary {
  //   .swiper-container {
  //     .swiper-button-prev {
  //       color: $primary-color;
  //       left: 0;
  //     }

  //     .swiper-button-next {
  //       color: $primary-color;
  //       right: 0;
  //     }

  //     .swiper-wrapper {
  //       padding: 20px 0px 34px;
  //       .swiper-slide {
  //         margin-left: 100px;
  //         &.swiper-slide-active,
  //         &.swiper-slide-next,
  //         &.swiper-slide-prev {
  //           margin-left: 0;
  //         }
  //       }
  //     }

  //     .swiper-pagination-bullets {
  //       bottom: -5px;

  //       .swiper-pagination-bullet {
  //         &.swiper-pagination-bullet-active {
  //           background-color: $teacher-secondary-dark-color;
  //         }
  //       }
  //     }
  //   }

  //   .bookItem {
  //     display: flex;

  //     .bookImg {
  //       padding-left: 38px;

  //       a {
  //         background-repeat: no-repeat;
  //         background-position: center;
  //         background-size: cover;
  //         width: 170px;
  //         height: 280px;
  //         // box-shadow: -20px 30px 40px rgba(0, 0, 0, 0.3);
  //         box-shadow: -10px 10px 25px rgba(0, 0, 0, 0.2);
  //         min-width: 170px;
  //       }

  //       .ui.image {
  //         // box-shadow: -45px 45px 50px 0px rgba(168, 168, 168, 0.1), -45px 45px 50px 0px rgba(168, 168, 168, 1),
  //         //   -45px 45px 50px 0px rgba(168, 168, 168, 1);
  //         box-shadow: -20px 30px 40px rgba(0, 0, 0, 0.3);
  //         min-width: 170px;
  //         width: 170px;
  //         height: 280px;
  //         cursor: pointer;
  //         object-fit: contain;
  //         display: none;
  //       }

  //       a {
  //         position: relative;
  //         display: block;

  //         &:before {
  //           content: "";
  //           background-color: #9b9b9b;
  //           display: block;
  //           height: 100%;
  //           left: 6px;
  //           position: absolute;
  //           width: 1px;
  //           opacity: 0.6;
  //           z-index: 1;
  //         }
  //       }
  //     }

  //     .bookInfo {
  //       padding: 0 0 0 58px;

  //       h3.ui.header {
  //         color: $teacher-primary;
  //         font-family: $app-medium-font;
  //         font-size: 28px;
  //         line-height: 35px;
  //         margin: 0;
  //         cursor: pointer;
  //         padding: 23px 0 0;
  //         display: inline-block;
  //       }

  //       h5.ui.header {
  //         margin: 1px 0 10px;
  //         color: $teacher-primary;
  //         font-family: $app-primary-semibold-font;
  //         font-size: 13px;
  //         letter-spacing: 0.31px;
  //         line-height: 15px;
  //         min-height: 15px;
  //       }

  //       p {
  //         color: #000000;
  //         font-size: 12px;
  //         letter-spacing: 0.28px;
  //         line-height: 20px;
  //         max-width: 316px;
  //         min-height: 81px;
  //       }

  //       span.recommendedFor {
  //         color: $teacher-primary;
  //         font-size: 12px;
  //         letter-spacing: 0.28px;
  //         line-height: 15px;
  //         display: block;
  //         margin: 10px 0 23px;
  //       }

  //       .btns {
  //         display: flex;
  //         align-items: center;

  //         img {
  //           margin: 0px 0 0 12px;
  //           cursor: pointer;
  //         }
  //       }
  //     }
  //   }

  //   .filters {
  //     height: 100%;
  //     display: flex;
  //     align-items: center;
  //     justify-content: flex-end;
  //     white-space: nowrap;
  //     padding: 0 0 0 20px;

  //     img.ui.image {
  //       max-width: 24px;
  //       margin: 0 10px 0 0;
  //     }

  //     .ui.dropdown {
  //       margin: 0 25px 0 0;
  //       // @media (max-width: 1300px) {
  //       //   margin: 0 10px 0 0;
  //       // }

  //       &.standard {
  //         .menu {
  //           max-height: initial;
  //           overflow: visible;
  //           border-radius: 0 !important;
  //           box-shadow: none;
  //           min-width: auto;
  //           max-width: 100px;

  //           .ui.list {
  //             .item {
  //               padding: 0.78571429rem 1.14285714rem !important;
  //               cursor: pointer;
  //               font-size: 12px;
  //               color: #013e52;
  //               display: flex;
  //             }
  //           }

  //           .firstMenu {
  //             min-width: 100px;
  //           }

  //           .secondMenu {
  //             position: absolute;
  //             left: 98px;
  //             top: -1px;
  //             border: 1px solid rgba(1, 62, 82, 0.38);
  //             border-radius: 5px;
  //             background-color: #fffcf6;
  //             min-width: 150px;
  //             border-radius: 0;
  //           }

  //           .thirdMenu {
  //             position: absolute;
  //             left: 148px;
  //             top: -1px;
  //             border: 1px solid rgba(1, 62, 82, 0.38);
  //             border-radius: 5px;
  //             background-color: #fffcf6;
  //             min-width: 120px;
  //             border-radius: 0;
  //           }

  //           .thirdMenued {
  //             position: absolute;
  //             left: 148px;
  //             top: -1px;
  //             border: 1px solid rgba(1, 62, 82, 0.38);
  //             border-radius: 5px;
  //             background-color: #fffcf6;
  //             width: 150px;
  //             border-radius: 0;
  //           }
  //         }
  //       }

  //       .menu {
  //         border: 1px solid rgba(1, 62, 82, 0.38);
  //         border-radius: 5px;
  //         background-color: #fffcf6;
  //         box-shadow: 0 23px 45px 11px rgba(0, 0, 0, 0.1);
  //         max-height: 200px;
  //         overflow: auto;

  //         &::-webkit-scrollbar-track {
  //           // -webkit-box-shadow: inset 0 0 5px rgba(1, 62, 82, 0.38);
  //           border-radius: 10px;
  //           background-color: transparent;
  //         }

  //         &::-webkit-scrollbar {
  //           width: 6px;
  //           background-color: transparent;
  //         }

  //         &::-webkit-scrollbar-thumb {
  //           border-radius: 10px;
  //           background-color: #bf964a;
  //           width: 6px;

  //           &:hover {
  //             background-color: #bf964a;
  //           }
  //         }

  //         &::after {
  //           display: none;
  //         }

  //         .item {
  //           .ui.checkbox {
  //             label {
  //               font-size: 12px;
  //               color: $teacher-primary;
  //             }
  //           }
  //         }

  //         &.transition {
  //           opacity: 0 !important;

  //           &.visible {
  //             opacity: 1 !important;
  //           }
  //         }

  //         &:hover {
  //           opacity: 1 !important;
  //         }
  //       }

  //       .text {
  //         color: #bf964a;
  //         font-family: $app-primary-medium-font;
  //         font-size: 12px;
  //         letter-spacing: 0.26px;
  //         line-height: 15px;

  //         // @media (max-width: 1300px) {
  //         //   font-size: 10px;
  //         // }
  //       }

  //       i.icon {
  //         font-size: 10px;
  //         margin: 0 0 0 5px;
  //         color: #bf964a;
  //         opacity: 1 !important;
  //       }
  //     }

  //     .ui.input {
  //       padding: 0;
  //       max-width: 110px;

  //       input {
  //         border: none;
  //         font-family: $app-primary-medium-font;
  //         color: #bf964a;
  //         background-color: transparent;
  //         font-size: 11px;

  //         // @media (max-width: 1300px) {
  //         //   font-size: 10px;
  //         // }

  //         &::placeholder {
  //           color: #bf964a;
  //           font-family: $app-primary-medium-font;
  //           font-size: 11px;

  //           // @media (max-width: 1300px) {
  //           //   font-size: 10px;
  //           // }
  //         }
  //       }

  //       i.icon {
  //         color: #bf964a;
  //         opacity: 1 !important;
  //         font-size: 11px;

  //         @media (max-width: 1300px) {
  //           font-size: 10px;
  //         }
  //       }
  //     }

  //     .filterIcon {
  //       color: $teacher-secondary-dark-color;
  //       opacity: 1;
  //       margin: -6px 10px 0 0;

  //       &:last-child {
  //         margin-right: 0;
  //       }
  //     }
  //   }
  // }

  .libraryBookItem {
    display: flex;
    margin: 0 0 25px;

    .libBookImg {
      // padding-left: 45px;
      a {
        // box-shadow: -25px 25px 30px 0px rgba(168, 168, 168, 0.1), -25px 25px 30px 0px rgba(168, 168, 168, 1),
        //   -25px 25px 30px 0px rgba(168, 168, 168, 1);
        box-shadow: -20px 30px 40px rgba(0, 0, 0, 0.3);
        min-width: 90px;
        max-width: 90px;
        height: 150px;
        cursor: pointer;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      a {
        position: relative;
        display: block;

        &:before {
          content: "";
          background-color: #9b9b9b;
          display: block;
          height: 100%;
          left: 5px;
          position: absolute;
          width: 1px;
          opacity: 0.6;
          z-index: 1;
        }
      }
    }

    .libraryBookInfo {
      padding: 0 20px 0 28px;
      width: 100%;

      h3.ui.header {
        color: $teacher-primary;
        font-family: $app-medium-font;
        font-size: 25px;
        line-height: 33px;
        margin: 0;
        cursor: pointer;
        display: inline-block;
      }

      h4.ui.header {
        color: $teacher-primary;
        font-family: $app-medium-font;
        font-size: 20px;
        line-height: 25px;
        margin: 0;
        cursor: pointer;
        display: inline-block;
      }

      h5.ui.header {
        color: $teacher-primary;
        font-size: 12px;
        letter-spacing: 0.28px;
        line-height: 15px;
        margin: 4px 0 10px;
        font-family: $app-primary-semibold-font;
      }

      p {
        color: $teacher-primary;
        font-size: 11px;
        letter-spacing: 0.26px;
        line-height: 19px;
        padding: 0 0 10px;
      }

      .btns {
        display: flex;
        align-items: center;

        img {
          margin: 0px 0 0 13px;
          cursor: pointer;
        }
      }

      .addnew {
        a {
          color: $teacher-secondary-dark-color;
          font-size: 13px;
          letter-spacing: 0.31px;
          line-height: 19px;
          margin: 0 15px 0 0;
          display: inline-block;
          font-family: $app-primary-semibold-font;
        }

        button {
          box-shadow: none;
        }
      }
    }
  }

  .ui.list.commonList {
    margin: 0 0 10px;

    .item {
      padding: 10px 5px;
      border-top: none;
      border-bottom: 1px solid rgba(1, 62, 82, 0.03);

      &.greyed-Student {
        .content {
          &.planName {
            color: #999;
          }

          span {
            color: #999;
          }
        }
      }

      .content {
        &.planName {
          color: $teacher-primary;
          font-size: 14px;
          letter-spacing: 0.33px;
          line-height: 19px;
          width: 35%;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.student {
          width: 20%;
        }
        &.email {
          width: 45%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          > span {
            max-width: 78%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &:nth-of-type(2) {
          span {
            margin: 0;
          }
        }

        span {
          color: $teacher-primary;
          font-size: 11px;
          letter-spacing: 0.26px;
          line-height: 19px;
          margin: 0 20px 0 0;
        }

        .editDropDown {
          i.icon {
            color: $teacher-secondary-dark-color;
            font-size: 15px;
            margin: 2px 0 0;

            &:hover {
              color: $teacher-primary;
            }
          }

          .menu {
            width: 130px;
            border: 1px solid rgba(1, 62, 82, 0.38);
            border-radius: 5px;
            background-color: #fffcf6;
            box-shadow: 0 23px 45px 11px rgba(0, 0, 0, 0.1);

            .item {
              text-transform: uppercase;
              padding: 5px 10px !important;
              color: $teacher-primary !important;
              font-family: $app-primary-medium-font;
              border-bottom: 1px solid rgba(1, 62, 82, 0.38);

              &:last-child {
                border: none;
              }

              span {
                margin: 0;
                color: $teacher-primary !important;
                font-size: 10px !important;
              }
            }
          }
        }
      }
    }

    &.mystudentList {
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &::after {
          display: none;
        }
      }
    }
  }

  // Book Detail Page
  .bookDetail {
    .detailWrapper {
      display: flex;
      margin: 65px 0 0;

      .detailImg {
        padding-left: 50px;

        .detailImgBox {
          // box-shadow: -25px 25px 30px 0px rgba(168, 168, 168, 0.1), -25px 25px 30px 0px rgba(168, 168, 168, 1),
          //   -25px 25px 30px 0px rgba(168, 168, 168, 1);
          box-shadow: -20px 30px 40px rgba(0, 0, 0, 0.3);
          min-width: 290px;
          height: 460px;
          cursor: pointer;
          width: 290px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 3px 6px 6px 3px;
        }

        a {
          position: relative;
          display: block;

          &:before {
            content: "";
            background-color: #9b9b9b;
            display: block;
            height: 100%;
            left: 6px;
            position: absolute;
            width: 1px;
            opacity: 0.6;
            z-index: 1;
          }
        }
      }

      .detailInfo {
        padding: 56px 0 0 104px;

        h3.ui.header {
          color: $teacher-primary;
          font-family: $app-medium-font;
          font-size: 40px;
          line-height: 50px;
          margin: 0;
          cursor: pointer;
          display: inline-block;
        }

        h5.ui.header {
          color: $teacher-primary;
          font-size: 13px;
          letter-spacing: 0.31px;
          line-height: 15px;
          margin: 3px 0 15px;
          font-family: $app-primary-semibold-font;
        }

        p {
          color: $teacher-primary;
          font-size: 12px;
          letter-spacing: 0.28px;
          line-height: 15px;
          padding: 20px 0 9px 0;
        }

        span.description {
          color: $black-color;
          font-size: 14px;
          letter-spacing: 0.33px;
          line-height: 26px;
          max-width: 831px;
          display: block;
          max-height: 170px;
          overflow: auto;
          -ms-overflow-style: none;
          /* IE and Edge */
          scrollbar-width: none;

          /* Firefox */
          &::-webkit-scrollbar {
            display: none;
          }

          a {
            color: $teacher-secondary-dark-color;
          }

          pre {
            font-family: $app-primary-regular-font;
            width: 100%;
            white-space: break-spaces;
            margin: 0;
          }
        }

        .btns {
          display: flex;
          align-items: center;
          margin: 49px 0 0;

          img {
            margin: 0px 0 0 25px;
            cursor: pointer;
          }
        }

        .purchaseBook {
          font-size: 12px;
          font-family: $app-primary-bold-font;
          line-height: 15px;
          letter-spacing: 0.28px;
          color: $teacher-secondary-dark-color;
          margin: 0 0 0 18px;
          cursor: pointer;
        }
      }
    }

    .detailTabs {
      .ui.secondary.menu {
        margin: 52px 0 0 -4px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.18);

        > .item {
          color: #003e52;
          border-width: 3px;
          font-family: $app-primary-regular-font;
          font-size: 13px;
          letter-spacing: 0.31px;
          line-height: 15px;
          padding: 11px 10px;
          margin: 0 15px 0 0;

          &.active {
            color: $teacher-secondary-color;
            border-color: $teacher-secondary-color;
            font-family: $app-primary-semibold-font;
          }
        }

        .howToUseALEE {
          .menu {
            min-width: 130px;
            max-height: calc(100vh - 190px);
            overflow: auto;
            border: 1px solid rgba(1, 62, 82, 0.38);
            border-radius: 5px;
            background-color: #fffcf6;
            box-shadow: 0 23px 45px 11px rgba(0, 0, 0, 0.1);

            .item {
              text-transform: uppercase;
              color: $teacher-primary !important;
              margin: 0;
              font-family: $app-primary-regular-font !important;

              i.icon {
                margin: -2px 0px 0px 8px;
                color: $teacher-secondary-dark-color;
              }

              span {
                margin: 0;
                color: $teacher-primary !important;
                font-size: 10px !important;
                font-family: $app-primary-medium-font;
                letter-spacing: 0.24px;
                line-height: 26px;
              }

              .ui.checkbox {
                label {
                  max-width: 200px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  color: $teacher-primary;
                }
              }
            }
          }

          &.standard {
            .menu {
              min-width: 100px;
              width: 150px;
              border-radius: 0;
              max-height: auto;
              overflow: initial;
            }

            .ui.list {
              .item {
                padding: 0.78571429rem 1.14285714rem !important;
                cursor: pointer;
                font-size: 12px;
                color: #013e52;
                display: flex;
                position: inherit;
              }
            }

            .secondMenu {
              position: absolute;
              left: 148px;
              top: -1px;
              border: 1px solid rgba(1, 62, 82, 0.38);
              border-radius: 5px;
              background-color: #fffcf6;
              width: 150px;
              border-radius: 0;

              .ui.list {
                padding: 0;
              }
            }

            .thirdMenu {
              position: absolute;
              left: 148px;
              top: -1px;
              border: 1px solid rgba(1, 62, 82, 0.38);
              border-radius: 5px;
              background-color: #fffcf6;
              min-width: 120px;
              border-radius: 0;
            }
          }
        }
      }

      .ui.tab {
        box-shadow: none;
        border: none;
        background-color: transparent;
        margin: 6px 23px 0 2px;

        .ui.list {
          cursor: pointer;

          .item {
            padding: 12px 13px 18px 18px;

            .content {
              h3.ui.header {
                color: $teacher-primary;
                font-family: $app-medium-font;
                font-size: 18px;
                line-height: 22px;
                margin: 0;
              }

              &.right {
                font-size: 13px;
                letter-spacing: 0.31px;
                line-height: 15px;
                text-align: right;
                color: $teacher-secondary-color;
                text-transform: uppercase;
                margin: 5px 0 0 0;

                i.icon {
                  margin: 0 0 0 5px;
                }
              }
            }
          }
        }

        .aboutAuthorBox {
          display: flex;

          .aboutAuthorImg {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 150px;
            width: 150px;
            min-height: 150px;
            min-width: 150px;
          }
        }

        .aboutAuthor {
          padding: 0 0 0 20px;

          h3.ui.header {
            color: $teacher-primary;
            font-family: $app-medium-font;
            font-size: 23px;
            letter-spacing: 0;
            line-height: 29px;
            margin: 0 0 9px;
          }

          p {
            color: $black-color;
            font-size: 13px;
            letter-spacing: 0.31px;
            line-height: 27px;
          }
        }
      }
    }

    // .bookSummary {
    //   position: fixed;
    //   left: 0;
    //   right: 0;
    //   top: 0;
    //   bottom: 0;
    //   background-color: rgba(255, 255, 255, 0.5);
    //   z-index: 1000;

    //   .bookSummaryContent {
    //     width: 726px;
    //     border: 1px solid rgba(1, 62, 82, 0.21);
    //     background-color: #fffcf6;
    //     box-shadow: -10px 23px 45px 11px rgba(0, 0, 0, 0.13);
    //     margin-left: auto;
    //     height: 100%;
    //     padding: 45px 27px 30px 45px;
    //     position: relative;

    //     .closeSummary {
    //       position: absolute;
    //       right: 22px;
    //       top: 22px;
    //       width: 20px;
    //       cursor: pointer;
    //     }

    //     h3.ui.header {
    //       color: $teacher-primary;
    //       font-family: $app-primary-extrabold-font;
    //       font-size: 16px;
    //       letter-spacing: 0.38px;
    //       line-height: 27px;
    //       margin: 0 0 8px;
    //     }

    //     .summaryDetails {
    //       overflow: auto;
    //       height: calc(100% - 38px);
    //       padding: 0 30px 0 0;

    //       &::-webkit-scrollbar-track {
    //         background: linear-gradient(to right,
    //             transparent 0%,
    //             transparent 35%,
    //             $teacher-primary 50%,
    //             transparent 61%,
    //             transparent 100%);
    //         border-radius: 5px;
    //       }

    //       &::-webkit-scrollbar {
    //         width: 5px;
    //       }

    //       &::-webkit-scrollbar-thumb {
    //         background-color: $teacher-primary;
    //         border-radius: 5px;
    //       }

    //       p {
    //         color: $black-color;
    //         font-size: 14px;
    //         letter-spacing: 0.33px;
    //         line-height: 26px;
    //         padding: 0 0 15px;
    //       }

    //       h4.ui.header {
    //         font-size: 16px;
    //         margin: 0 0 5px;
    //         color: $teacher-primary;
    //         font-family: $app-primary-semibold-font;
    //       }
    //     }
    //   }
    // }
  }

  .student {
    .liberaryHeading.ui.header {
      font-size: 34px;
      line-height: 42px;
      margin: 28px 0 13px !important;
    }

    .rightWrapper {
      width: 100%;
      padding: 0 10px 0 0;

      .mystudentList {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        > .item {
          width: 48%;
        }
      }
    }

    .uploadNote {
      color: $black-color;
      font-size: 14px;
      letter-spacing: 0.33px;
      line-height: 26px;

      span {
        font-family: $app-primary-bold-font;
      }

      a {
        color: #c19749;
        font-family: $app-primary-bold-font;
      }
    }

    .uploadStudent {
      border: 1px solid rgba(1, 62, 82, 0.24);
      border-radius: 27px;
      background-color: rgba(255, 243, 217, 0.24);
      text-align: center;
      margin: 0 30px 15px 0;
      overflow: hidden;
      position: relative;
      width: 280px;
      height: 218px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 0 0;

      h3.ui.header {
        color: $teacher-primary;
        font-family: $app-medium-font;
        font-size: 28px;
        line-height: 37px;
        text-align: center;
        margin: 0 0 7px;
      }

      img.ui.image {
        width: 23px;
      }

      i.icon {
        font-size: 25px;
        color: $teacher-primary;
      }

      .ui.input {
        position: absolute;
        inset: 0;
        opacity: 0;
        left: -100px;

        input {
          cursor: pointer;
        }
      }
    }

    .addNewStudent {
      color: $teacher-secondary-dark-color;
      font-family: $app-primary-semibold-font;
      font-size: 13px;
      letter-spacing: 0.31px;
      line-height: 19px;
      cursor: pointer;
    }

    .myStudent.ui.header {
      color: $teacher-primary;
      font-family: $app-medium-font;
      font-size: 28px;
      line-height: 35px;
      margin: 0 0 6px;
    }

    .uploadDate {
      color: $teacher-primary;
      font-size: 12px;
      letter-spacing: 0.28px;
      line-height: 15px;
      padding: 0 0 10px;
    }
  }

  // .aleeCoachPage {
  //   .                                                           {
  //     padding: 0 !important;
  //     .liberaryHeading {
  //       &.titleHead {
  //         margin: 35px 0 14px !important;
  //       }
  //     }
  //   }
  //   .searchInput {
  //     .ui.input {
  //       width: 100%;
  //       input {
  //         border-color: $teacher-secondary-color;
  //         background-color: transparent;
  //         &::placeholder {
  //           color: $teacher-secondary-color;
  //         }
  //       }
  //       i.icon {
  //         color: $teacher-secondary-color;
  //       }
  //     }
  //   }
  //   .bookDetail {
  //     padding-top: 0;
  //     .detailTabs {
  //       .ui.secondary.menu {
  //         margin: 0;
  //       }
  //     }
  //   }
  //   .backtoAlee {
  //     border-bottom: 1px solid rgba(0, 0, 0, 0.18);
  //     padding: 0 0 10px;
  //     a {
  //       color: $teacher-primary;
  //       font-size: 13px;
  //       letter-spacing: 0.31px;
  //       line-height: 15px;
  //     }
  //   }
  //   .aleeCoachSummary {
  //     p {
  //       color: $black-color;
  //       font-size: 13px;
  //       letter-spacing: 0.31px;
  //       line-height: 27px;
  //       span {
  //         color: $teacher-primary;
  //         font-family: $app-primary-bold-font;
  //       }
  //     }
  //     h3.ui.header {
  //       color: $teacher-primary;
  //       font-family: $app-medium-font;
  //       font-size: 23px;
  //       line-height: 29px;
  //       margin: 18px 0 9px;
  //     }
  //     iframe {
  //       width: 100%;
  //       margin: 32px 0;
  //     }
  //   }
  // }
  .lesson {
    .libraryBookItem {
      .libraryBookInfo {
        h4.ui.header {
          font-size: 25px;
          line-height: 33px;
        }
      }
    }
  }

  .bookShowWrapper {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 1px;
      right: 1px;
      bottom: 1px;
      height: 120px;
      z-index: 1;
      background: linear-gradient(180deg, transparent, rgba(255, 255, 255, 0.5));
      background: -webkit-linear-gradient(bottom, #fff 0%, rgba(255, 255, 255, 0.2) 100%);
      /* Chrome10+,Safari5.1+ */
    }
    .shadow {
      display: none;
    }
    &.active {
      .shadow {
        position: absolute;
        inset: 0;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 1001;
        display: block;
        cursor: pointer;
      }
    }
  }

  &.menuCollapse {
    .showBook {
      left: 58px;
    }
  }

  .showBook {
    position: fixed;
    inset: 0;
    left: 150px;
    background-color: rgba(1, 62, 82, 0.97);
    z-index: 1000;

    .closeIcon {
      position: fixed;
      right: 30px;
      top: 20px;
      width: 20px;
      z-index: 1000;
      cursor: pointer;
      display: block;
    }
  }

  .bookShowWrapper {
    z-index: 1000;

    &::-webkit-scrollbar-track {
      background: linear-gradient(
        to right,
        transparent 0%,
        transparent 35%,
        #fff 50%,
        transparent 61%,
        transparent 100%
      );
      border-radius: 5px;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #fff;
      border-radius: 5px;
      position: relative;
      z-index: 9999;
    }

    .bookContentInner {
      z-index: 1000;
    }
  }
}

// Create Profile
.createProfile {
  padding: 55px 0 0 0;

  h2.ui.header {
    color: #003e52;
    font-family: $app-medium-font;
    font-size: 34px;
    line-height: 42px;
    margin: 0 0 5px;
  }

  h4.ui.header.subHeading {
    color: #003e52;
    font-family: $app-medium-font;
    font-size: 20px;
    line-height: 30px;
    margin: 10px 0 0 6px;
  }

  .subHead {
    opacity: 0.23;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0.33px;
    line-height: 26px;
  }

  .description {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0.33px;
    line-height: 26px;
    margin: 20px 0 0;

    a {
      color: $primary-color;
      text-decoration: underline;
    }
  }

  .ui.form {
    margin: 23px 0 0;

    .downSelect {
      .ui.selection.dropdown {
        .menu {
          max-height: calc(100vh - 563px);
          min-height: 100px;
        }
      }
    }

    .maritalStatus {
      .ui.selection.dropdown {
        .menu {
          max-height: calc(100vh - 689px);
          min-height: 100px;
        }
      }
    }

    .actionBtns {
      display: flex;
      align-items: center;
      padding: 32px 0 0;
      grid-gap: 16px;

      .uploadImg {
        color: $primary-color;
        font-size: 13px;
        font-family: $app-primary-semibold-font;
        letter-spacing: 0.31px;
        line-height: 19px;
        position: relative;
        overflow: hidden;
        display: inline-block;
        cursor: pointer;

        input {
          position: absolute;
          left: 0;
          opacity: 0;
          top: 0;
          cursor: pointer;
        }
      }

      .uploadedImg {
        img.ui.image {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .saveProfile {
        color: $teacher-secondary-dark-color;
        font-size: 13px;
        font-family: $app-primary-semibold-font;
        letter-spacing: 0.31px;
        line-height: 19px;
        border: 1.5px solid #bf964a;
        background-color: transparent;
      }
    }

    .fields {
      margin: 0 0 23px;
      column-gap: 58px;
      grid-template-columns: 1fr 1fr 1fr;
      margin: 0 0 23px;
      display: grid;

      &.twoCol {
        grid-template-columns: 1fr 1fr;
      }

      .field {
        label,
        p {
          // opacity: 0.23;
          color: #3f3f3f;
          font-size: 11px;
          letter-spacing: 0.26px;
          line-height: 26px;
          margin: 0 0 5px;
          text-transform: uppercase;
        }

        .ui.input {
          input {
            height: 58px;
            border: 1px solid rgba(0, 0, 0, 0.18);
            border-radius: 10px;
          }
        }

        textarea {
          min-height: 58px;
          border: 1px solid rgba(0, 0, 0, 0.18);
          border-radius: 10px;
        }

        .ui.selection.dropdown {
          height: 58px;
          border: 1px solid rgba(0, 0, 0, 0.18);
          border-radius: 10px;
          display: flex;
          align-items: center;
          padding: 20px 35px 20px 20px;

          .text {
            margin: 0;
            color: #000000;
            font-size: 14px;
            letter-spacing: 0.33px;
            line-height: 26px;
            position: initial;

            &.default {
              opacity: 0.45;
            }

            img.ui.image {
              position: absolute;
              right: 14px;
              top: 50%;
              transform: translateY(-50%);
              margin: 0;
              height: 6.5px;
              width: 11.5px;
            }
          }

          .dropdown.icon {
            height: 100%;
            display: flex;
            align-items: center;
          }

          .menu {
            border: 1px solid rgba(0, 0, 0, 0.18);
          }
        }

        &.disabled {
          opacity: 1;

          label {
            opacity: 0.45;
          }
        }
      }
    }
  }
}

.justifyContentEnd {
  justify-content: flex-end;
}

.teacher-profile-img {
  display: flex;
  align-items: center;

  .teacher-profile-img-inner {
    img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .ui.button {
    position: relative;
    background-color: transparent;
    color: $primary-color;

    input {
      opacity: 0;
      position: absolute;
      inset: 0;
      left: -80px;
      cursor: pointer;
    }
  }
}

.coverImg {
  position: relative;

  img.ui.image {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 50%;
  }

  i {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
}

.certifiedProfileRadioBtns {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0 0;

  .field {
    label {
      &::before {
        top: 5px !important;
      }

      &::after {
        top: 5px !important;
      }
    }
  }
}

.lessonViewAll {
  display: flex;

  .leftWrapper {
    width: 30%;
    padding: 0 15px 0 0;

    h2.ui.header {
      color: $teacher-primary;
      font-family: $app-medium-font;
      font-size: 34px;
      line-height: 42px;
      margin: 0 0 5px;
    }

    h5.ui.header {
      color: $teacher-primary;
      font-family: $app-primary-semibold-font;
      font-size: 13px;
      letter-spacing: 0.31px;
      line-height: 15px;
      margin: 0 0 16px;
    }

    .libBookImg {
      a {
        box-shadow: -20px 30px 40px rgba(0, 0, 0, 0.3);
        min-width: 170px;
        width: 170px;
        height: 280px;
        cursor: pointer;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
      }
    }

    .addNew {
      color: $teacher-secondary-dark-color;
      font-family: $app-primary-semibold-font;
      font-size: 13px;
      letter-spacing: 0.31px;
      line-height: 19px;
      margin: 30px 0 0;
      display: inline-block;
      cursor: pointer;
    }
  }

  .rightWrapper {
    width: 70%;
    overflow: auto;
    height: calc(100vh - 100px);

    .commonList.ui.list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > .item {
        width: 47%;
      }
    }
  }
}

.chapters {
  padding: 35px 0 0;
  height: 100%;

  .closeImg {
    position: absolute !important;
    right: 14px;
    top: 15px;
    width: 16px;
    cursor: pointer;
  }

  .chaptersHeader {
    display: flex;
    align-items: center;
    gap: 14px;
    margin: 0 0 12px;

    img.ui.image {
      width: 15px;
    }

    h4.ui.header {
      color: $teacher-primary;
      font-family: $app-primary-bold-font;
      font-size: 14px;
      letter-spacing: 0.33px;
      line-height: 25px;
      margin: 0;
      text-transform: uppercase;
    }
  }

  .chaptersBody {
    height: calc(100% - 40px);

    .ui.list {
      margin: 0 0 19px;
      height: 100%;
      overflow: auto;

      .item {
        padding: 13px 6px;
        border-bottom: 1px solid rgba(1, 62, 82, 0.13);
        color: $teacher-primary;
        font-family: $app-primary-regular-font;
        font-size: 14px;
        letter-spacing: 0.33px;
        line-height: 19px;

        &:first-child {
          border-top: 1px solid rgba(1, 62, 82, 0.13);
        }

        &.backtobook {
          color: $teacher-secondary-dark-color;
          font-family: $app-primary-bold-font;
          font-size: 12px;
          letter-spacing: 0.28px;
          line-height: 15px;
          border: none;
          cursor: pointer;
          position: sticky;
          bottom: 0;
          background-color: #fff;
        }
      }
    }
  }
}

.addStudentModal {
  border: 0.5px solid rgba(1, 62, 82, 0.38);
  background-color: #ffffff;
  box-shadow: 0 10px 47px 1px rgba(0, 0, 0, 0.31);
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 22px 22px 26px 26px;
  border-radius: 4px;
  z-index: 1060;

  h2.ui.header {
    color: $teacher-primary;
    font-family: $app-primary-bold-font;
    font-size: 14px;
    letter-spacing: 0.33px;
    line-height: 25px;
  }

  img.ui.image {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 15px;
    cursor: pointer;
  }

  .addStudentBody {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ui.fluid.dropdown {
      width: 63%;
      border: 1px solid rgba(1, 62, 82, 0.28);
      background-color: #ffffff;
      min-height: 39px;
      align-items: center;
      display: flex;
      padding: 10px;
      border-radius: 5px;

      i.icon.chevron.down {
        position: absolute;
        right: 0;
        top: 12px;
        color: $teacher-secondary-dark-color;
      }

      .text {
        color: $teacher-secondary-dark-color;
        font-family: $app-primary-medium-font;
        font-size: 12px;
        letter-spacing: 0.28px;
        line-height: 15px;
        margin: 5px 0 0 5px;
      }

      .menu {
        left: -1px;
        right: -1px;
        border: 1px solid rgba(1, 62, 82, 0.28);
        background-color: #ffffff;
        box-shadow: none;
        border-radius: 0 0 5px 5px;
        border-top: none;
        max-height: 120px;
        overflow: auto;

        .item {
          &:hover {
            background-color: transparent;
          }

          .ui.checkbox {
            input {
              &:checked {
                ~ label {
                  &::before {
                    opacity: 1;
                  }

                  &::after {
                    height: 12px;
                    width: 12px;
                    font-size: 8px;
                  }
                }
              }

              &:focus {
                ~ label {
                  &::before {
                    border-color: $teacher-primary !important;
                  }
                }
              }
            }

            label {
              color: #000000;
              font-family: $app-primary-medium-font;
              font-size: 12px;
              letter-spacing: 0.28px;
              max-width: 225px;
              overflow: hidden;
              text-overflow: ellipsis;

              &::before {
                height: 12px;
                width: 12px;
                border: 1px solid #013e52;
                opacity: 0.72;
                border-radius: 0;
                top: 2px;
              }
            }
          }
        }
      }
    }

    .ui.button {
      width: 30%;
      background-color: $teacher-secondary-dark-color;
      color: #ffffff;
      font-family: $app-primary-bold-font;
      font-size: 12px;
      letter-spacing: 0.28px;
      line-height: 15px;
      text-align: center;
      height: 39px;
      border-radius: 0;
    }
  }
}

.studentGrayBg {
  opacity: 0.61;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 58px;
  right: 0;
  bottom: 0;
  z-index: 1050;
}

.chooseGradeModal.ui.modal {
  border: 0.5px solid rgba(1, 62, 82, 0.15);
  background-color: #ffffff;
  box-shadow: 0 10px 47px 1px rgba(0, 0, 0, 0.31);

  .content {
    padding: 21px 21px 28px 26px;
  }

  h3.ui.header {
    color: $teacher-primary;
    font-family: $app-primary-bold-font;
    font-size: 14px;
    letter-spacing: 0.33px;
    line-height: 25px;
  }

  img.ui.image.close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 15px;
    cursor: pointer;
  }

  .chooseGradeBody {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ui.fluid.dropdown {
      width: 335px;
      border: 1px solid rgba(1, 62, 82, 0.28);
      background-color: #ffffff;
      height: 39px;
      align-items: center;
      display: flex;
      padding: 10px 34px 10px 10px;
      border-radius: 0;

      i.icon.chevron.down {
        position: absolute;
        color: $teacher-secondary-dark-color;
        right: 12px;
        top: 17.5px;
        margin: 0 !important;
      }

      img.ui.image {
        position: absolute;
        right: 13px;
        top: 15px;
        height: 6.5px;
        width: 11.5px;
        margin: 0;
      }

      .text {
        color: $teacher-secondary-dark-color;
        font-family: $app-primary-medium-font;
        font-size: 12px;
        letter-spacing: 0.28px;
        line-height: 15px;
      }

      .menu {
        left: -1px;
        right: -1px;
        border: 1px solid rgba(1, 62, 82, 0.28);
        background-color: #ffffff;
        box-shadow: none;
        border-radius: 0;
        border-top: none;
        max-height: 180px;
        overflow: auto;

        .item {
          &:hover {
            background-color: transparent;
          }

          .ui.checkbox {
            &.checked {
              label {
                &::before {
                  opacity: 1;
                  background-color: $primary-color;
                }
              }
            }

            input {
              &:checked {
                ~ label {
                  &::before {
                    opacity: 1;
                  }
                }
              }

              &:focus {
                ~ label {
                  &::before {
                    border-color: $teacher-primary !important;
                  }
                }
              }
            }

            label {
              color: #000000;
              font-family: $app-primary-medium-font;
              font-size: 12px;
              letter-spacing: 0.28px;

              &::before {
                height: 12px;
                width: 12px;
                border: 1px solid #013e52;
                opacity: 0.72;
                border-radius: 50%;
                top: 2px;
              }

              &::after {
                display: none;
              }
            }
          }
        }
      }
    }

    .ui.button {
      width: 140px;
      background-color: $teacher-secondary-dark-color;
      color: #ffffff;
      font-family: $app-primary-bold-font;
      font-size: 12px;
      letter-spacing: 0.28px;
      line-height: 15px;
      text-align: center;
      height: 39px;
      border-radius: 0;
      margin: 0 0 0 20px;
    }
  }
}

.customDivider {
  background-color: #012027;
  height: 1px;
  margin: auto;
  opacity: 0.2;
}

.liberaryBooks.ui.grid {
  padding: 0 0 0 32px;
}

.liberaryHeading.ui.header {
  color: $teacher-primary;
  font-family: $app-medium-font;
  font-size: 30px;
  line-height: 38px;
  margin: 0 !important;

  @media (max-width: 1300px) {
    font-size: 24px;
    line-height: 34px;
  }
}

.predefineLesson {
  position: relative;

  .ui.dropdown.recent-editDropDown {
    position: absolute;
    right: 13px;
    color: #c19749;
    font-size: 15px;
    top: 0;

    .menu {
      width: 130px;
      border: 1px solid rgba(1, 62, 82, 0.38);
      border-radius: 5px;
      background-color: #fffcf6;
      box-shadow: 0 23px 45px 11px rgba(0, 0, 0, 0.1);

      .item {
        text-transform: uppercase;
        padding: 5px 10px !important;
        color: $teacher-primary !important;
        font-family: $app-primary-medium-font;
        border-bottom: 1px solid rgba(1, 62, 82, 0.38);

        &:last-child {
          border: none;
        }

        span {
          margin: 0;
          color: $teacher-primary !important;
          font-size: 10px !important;
        }
      }
    }
  }
}

.inActiveRecords {
  opacity: 0.5;
}

.boldStatus {
  font-weight: 700;
}
