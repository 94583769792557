.login {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  height: 100vh;
  background-color: #013e52;

  .loginInner {
    max-width: 525px;
    width: 100%;
    align-self: center !important;

    .loginInnerBox {
      padding: 50px 45px;
      border-radius: 25px;
      background-color: #fffcf8;

      img.ui.image.logo {
        width: 206px;
        margin: 0 auto;
      }

      .ui.form {
        padding: 38px 0 0;
        .field {
          &.error {
            .ui.input {
              margin: 0;
              input {
                background: #fff6f6;
                border-color: #e0b4b4;
                color: #9f3a38;
                &::placeholder {
                  color: #9f3a38;
                }
              }
            }
          }
        }
        .ui.input {
          margin: 0 0 13px;

          input {
            border-radius: 6px;
            border: 1px solid rgba(0, 0, 0, 0.14);
            background-color: #ffffff;
            height: 46px;

            &::placeholder {
              color: #000000;
              font-size: 14px;
              letter-spacing: -0.23px;
              line-height: 21px;
            }
          }
        }

        .forgotPassword {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          margin: 0 0 15px;
          grid-gap: 10px;

          .field {
            margin: 0;
          }

          a {
            color: $primary-color;
            font-family: $app-primary-semibold-font;
          }
        }

        .password {
          position: relative;

          i.icon.passwordIcon {
            position: absolute;
            right: 7px;
            top: 14px;
            font-size: 20px;
            cursor: pointer;
          }
        }

        .loginBtn {
          height: 45px;
          background-color: #bf964a;
          color: #ffffff;
          font-family: $app-primary-bold-font;
          font-size: 12px;
          letter-spacing: 0.28px;
          line-height: 15px;
          text-align: center;
          margin: 5px 0 0;
          border-radius: 0;
        }
      }

      .or {
        color: #909090;
        font-size: 13px;
        letter-spacing: -0.22px;
        line-height: 21px;
        text-align: center;
        margin: 19px 0 10px;
      }

      .googleBtn {
        display: flex;
        align-items: center;
        height: 46px;
        border: 1px solid rgba(0, 0, 0, 0.14);
        background-color: #ffffff;
        justify-content: center;
        font-size: 14px;
        letter-spacing: -0.23px;
        line-height: 21px;
        color: #000000;
        border-radius: 0;

        img.ui.image {
          width: 20px;
          margin: 0 40px 0 0;
        }
      }

      h3 {
        font-size: 20px;
      }

      h4.ui.header {
        font-size: 20px;
        font-family: $app-primary-medium-font;
        color: $teacher-primary;
        text-align: center;
        padding: 11px 0 0;
        font-weight: normal;
      }
      &.resetPassword {
        .field {
          &.error {
            .ui.input {
              margin: 0!important;
              input {
                background: #fff6f6!important;
                border-color: #e0b4b4!important;
                color: #9f3a38!important;
                box-shadow: none;
                &::placeholder {
                  color: #9f3a38!important;
                }
              }
            }
          }
        }
      }
    }

    .notAccount {
      color: #ffffff;
      font-size: 15px;
      letter-spacing: -0.25px;
      line-height: 21px;
      text-align: center;
      margin: 22px 0 0;

      a {
        color: #c19749;
      }
    }
  }
}




// old css
.signIn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  height: 100vh;
  background-color: $secondary-color;

  .signInner {
    max-width: 800px;
    width: 100%;
    align-self: center !important;
    padding: 15px;
    border-radius: 10px;

    &.resetPassword {
      max-width: 400px;
      background-color: $white-color;

      h3.ui.header {
        color: $primary-color;
        font-size: 25px;
        text-align: center;
      }
    }

    .ui.form {
      .field {
        label {
          font-weight: 400;
          color: rgba(0, 0, 0, 0.87) !important;
        }

        .ui.input {
          input {
            border: none;
            border-bottom: 1.5px solid $secondary-color;
            border-radius: 0;
            padding: 5px;
          }
        }
      }

      .controlField {
        .react-tel-input {
          .special-label {
            margin: 0 0 0.28571429rem 0;
          }

          .form-control {
            border: none;
            border-bottom: 1.5px solid $secondary-color;
            border-radius: 0;
            padding: 5px;
            height: 36px;
          }
        }
      }
    }

    .signInnerLeft {
      // background-color: $primary-color;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 10px;
      z-index: 2;

      &:before {
        content: "";
        position: absolute;
        right: -40px;
        bottom: 0;
        left: 0;
        top: 0;
        background-size: 100% 100%;
      }

      img.ui.image {
        max-width: 120px;
      }

      p {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        color: #ddd;
        font-size: 13px;
        text-align: center;

        a {
          color: #fff;
          padding-left: 2px;
        }
      }
    }

    .signInnerRight {
      padding: 30px 15px !important;

      .error {
        position: relative;
      }

      .ui.grid {
        background-color: $white-color;
        padding: 30px 15px 30px 50px !important;
        border-radius: 0 10px 10px 0;

        h2.ui.header {
          color: $primary-color;
          font-size: 25px;
        }
      }

      .passwordIcon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
      }

      .loginPassword {
        position: relative;

        .passwordIcon {
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
        }
      }

      .ui.buttons {
        margin: 0;

        .ui.button {
          background-color: $secondary-color;
          color: $primary-color;
          padding: 10px 20px;

          &:first-child {
            border-radius: 25px 0 0 25px;
          }

          &:last-child {
            border-radius: 0 25px 25px 0;
          }

          &.active {
            background-color: $primary-color  !important;
            color: $white-color  !important;
          }
        }
      }
    }
  }
}

.resetHeading {
  text-align: center;
}