@import "variables.scss";

.Admin {
  .Header {
    position: fixed;
    top: 0;
    right: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 2;
    background-color: $white-color;
    transition: all 0.5s ease-out;
    display: flex;
    justify-content: space-between;
    left: 150px;
    align-items: center;
    @media (max-width: 1199px) {
      left: 58px;
    }
    .menuBar {
      z-index: 1000;
      width: 20px;
      cursor: pointer;
      i.icon {
        background-color: $primary-color;
        height: 35px;
        width: 35px;
        color: $white-color;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        opacity: 1;
        font-size: 18px;
      }
      img.ui.image.right {
        display: none;
      }
    }
    .headerRightcontainer {
      display: flex;
      align-items: center;
      .profile {
        .ui.dropdown {
          display: flex;
          align-items: center;
          white-space: nowrap;
          span {
            display: inline-flex;
            align-items: center;
            img.ui.image {
              max-width: 35px;
              margin-left: 10px;
              height: 35px;
              border-radius: 50%;
              overflow: hidden;
              object-fit: cover;
            }
          }
          .menu {
            right: 0 !important;
            left: initial !important;
            > .item {
              &:hover {
                background-color: $primary-color;
                color: $white-color;
              }
            }
          }
        }
      }
      .notificationDropdown.ui.dropdown {
        padding: 0;
        margin-right: 40px;
        > .text {
          position: absolute;
          top: -12px;
          right: -4px;      
          background-color: $secondary-color;
          color: $primary-color;
          height: 20px;
          width: 20px;
          text-align: center;
          border-radius: 50%;
          font-size: 12px;
          line-height: 20px;
          margin: 0;
        }
        i.icon,
        i.icons {
          font-size: 25px;
          color: $primary-color;
        }
        .menu {
          min-width: initial !important;
          width: 300px;
          .ui.card {
            width: 100%;
            white-space: initial !important;
            .content.body {
              padding: 0 !important;
              .ui.feed {
                .event {
                  padding: 10px !important;
                  &:hover {
                    background-color: $secondary-color;
                  }
                  .content {
                    .summary {
                      cursor: pointer;
                      font-weight: 100;
                      color: $primary-color !important;
                      a {
                        color: $primary-color !important;
                        font-weight: 600;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.App {
    &.menuCollapse {
      .Header {
        .menuBar {
          i.icon {
            &:before {
              transform: matrix(-1, 0, 0, 1, 0, 0);
            }
          }
          img.ui.image.right {
            display: block;
          }
          img.ui.image.left {
            display: none;
          }
        }
      }
    }
  }

  .passwordIcon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}


.Teacher , .School, .District{
  .Header {
    .menuBar {
      position: fixed;
      left: 170px;
      top: 22px;
      z-index: 1021;
      width: 20px;
      cursor: pointer;
      transition: all 0.5s ease-out;
      @media (max-width: 1200px) {
        left: 80px;
      }
      img.ui.image.right {
        display: none;
      }
    }
    // .profile {
    //   display: flex !important;
    //   justify-content: flex-end;
    //   position: fixed;
    //   right: 20px;
    //   top: 10px;
    //   z-index: 1000;
    //   .ui.dropdown {
    //     display: flex;
    //     align-items: center;
    //     span {
    //       display: inline-flex;
    //       align-items: center;
    //       font-family: $app-primary-medium-font;
    //       color: $teacher-primary;
    //       img.ui.image {
    //         width: 41px;
    //         margin-left: 5px;
    //         height: 41px;
    //         border-radius: 50%;
    //         overflow: hidden;
    //         object-fit: cover;
    //         border: 3px solid $teacher-secondary-color;
    //       }
    //     }
    //     .menu {
    //       right: 0 !important;
    //       left: initial !important;
    //       .item {
    //         i.icon {
    //           color: $teacher-primary;
    //         }
    //         &:hover {
    //           background-color: $teacher-primary;
    //           color: $white-color !important;
    //           span,
    //           i.icon {
    //             color: $white-color !important;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    
  }

  &.App {
    &.menuCollapse {
      .Header .menuBar {
        // left: 100px;
        // transition: all 0.5s ease-out;
        img.ui.image.right {
          display: block;
        }
        img.ui.image.left {
          display: none;
        }
      }
    }
  }

  .passwordIcon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}

.Teacher , .School , .District{
  .profile {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 999;
    .ui.dropdown {
      display: flex;
      align-items: center;
      span {
        display: inline-flex;
        align-items: center;
        color: #003e52;
        font-family: $app-primary-medium-font;
        font-size: 11px;
        letter-spacing: 0.26px;
        line-height: 15px;
        img.ui.image {
          width: 41px;
          margin-left: 10px;
          height: 41px;
          border-radius: 50%;
          overflow: hidden;
          object-fit: cover;
          border: 2px solid #bf964a;
        }
      }
      .menu {
        right: 0 !important;
        left: initial !important;
        > .item {
          span {
            font-size: 13px;
          }
          
          &:hover {
            background-color: $teacher-primary;
            color: $white-color;
            span {
              color: $white-color;
            }
          }
        }
      }
    }
  }
}