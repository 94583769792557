@import "variables.scss";

.Sidebar {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	width: 150px;
	background-color: $teacher-primary;
	transition: all 0.5s ease-out;
	z-index: 2;
	@media (max-width: 1199px) {
		width: 58px;
	}
	.logo {
		padding: 21px 0 0 10px;
    	height: 70px;
		margin: 0 0 50px;
		a {
			display: inline-block;
			position: relative;
			height: 100%;
			width: 100%;
			img.ui.image {
				max-width: 120px;
				transition: all 0.5s ease-out;
				position: absolute;
				left: 0;
				top: 0;
				@media (max-width:1199px) {
					max-width: 60px;
				}
				&.small {
					max-width: 34px!important;
					@media (min-width:1200px) {
						// display: none;
						opacity: 0;
					}
				}
				&.big {
					@media (max-width:1199px) {
						// display: none;
						opacity: 0;
					}
				}
			}
		}
	}
	.mainMenu {
		overflow: auto;
		height: calc(100vh - 115px);
		-ms-overflow-style: none;
		scrollbar-width: none; 
		&::-webkit-scrollbar {
			display: none;
		}
		.topic {
			display: flex;
			justify-content: space-between;
		}
		.subMenu {
			margin-left: 15px !important;
		}

		.ui.menu {
			width: 100%;
			.item {
				color: #FFF1D6;
				border-radius: 0 !important;
				padding: 5px !important;
				margin: 0 0 20px !important;
				display: flex;
				align-items: center;
				flex-direction: column;
				border-right: 4px solid transparent !important;
				font-family: $app-primary-regular-font;
				cursor: pointer;
				img.ui.image {
					&.blue {
						display: none;
					}
				}
				span {
					margin-top: 11px;
					text-align: center;
					@media (max-width:1199px) {
						display: none;
					}
				}
				&.chapterMenu {
					margin-left: 10px !important;
				}
				&:hover {
					color: #D9AE8A;
					border-color: #D9AE8A !important;
					font-family: $app-primary-medium-font;
					.blue {
						display: block !important;
					}
					.white {
						display: none !important;
					}
				}
				&.active {
					color: #D9AE8A;
					border-color: #D9AE8A !important;
					font-family: $app-primary-medium-font;
					&:hover {
						color: #D9AE8A;
					}
					.blue {
						display: block !important;
					}
					.white {
						display: none !important;
					}
				}
				&.lessonPlan {
					position: relative;
					.icon {
						position: absolute;
						right: 10px;
						top: 12px;
					}
				}
			}
			.lessonPlanMenu {
				padding-left: 20px  !important;
				display: none;
				&.show {
					display: block;
				}
			}
		}
	}
}



.App {
	&.menuCollapse {
		.Sidebar {
			transition: all 0.5s ease-out;
			@media (min-width: 1200px) {
				width: 58px;
			}
			@media (max-width: 1200px) {
				width: 150px;
			}

			.logo {
				img.ui.image {
					@media (min-width: 1200px) {
						max-width: 60px;
						transition: all 0.5s ease-out;
						&.small {
							// display: initial;
							opacity: 1;
						}
						&.big {
							opacity: 0;
							transition: all 0.4s ease-out;
						}
					}
					@media (max-width: 1199px) {
						&.small {
							display: none;
							opacity:0;
						}
						&.big {
							opacity: 1;
							max-width: 120px;
						}
					}
				}
			}
			.mainMenu {
				.ui.menu .item.lessonPlan .icon {
					right: -4px;
				}
				.item {
					span {
						@media (min-width:1200px) {
							display: none;
						}
						@media (max-width:1199px) {
							display: block;
						}
					}
				}
			}
		}
	}
}
