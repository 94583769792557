@font-face {
    font-family: 'PPHatton-Medium';
    src: url('../fonts/PPHatton-Medium.woff2') format('woff2'),
         url('../fonts/PPHatton-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'PPHatton-Regular';
    src: url('../fonts/PPHatton-Regular.woff2') format('woff2'),
         url('../fonts/PPHatton-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'inter-medium';
    src: url('../fonts/inter-medium-webfont.woff2') format('woff2'),
         url('../fonts/inter-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'inter-regular';
    src: url('../fonts/inter-regular-webfont.woff2') format('woff2'),
         url('../fonts/inter-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'inter-semibold';
    src: url('../fonts/inter-semibold-webfont.woff2') format('woff2'),
         url('../fonts/inter-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'inter-bold';
    src: url('../fonts/inter-bold-webfont.woff2') format('woff2'),
         url('../fonts/inter-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'inter-extrabold';
    src: url('../fonts/inter-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/inter-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'times_new_roman';
    src: url('../fonts/times_new_roman-webfont.woff2') format('woff2'),
         url('../fonts/times_new_roman-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
