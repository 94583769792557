$primary-color: #253b49;
$secondary-color: #f2f2f2;
$white-color: #fff;
$gray-color: #999;
$black-color: #000;
$red-color: #e95950;

@mixin horizontal-scroll {
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 10px;
		background-color: $secondary-color;
	}

	&::-webkit-scrollbar {
		width: 10px;
		background-color: $secondary-color;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: $primary-color;
		&:hover {
			background-color: $primary-color;
		}
	}
}

@mixin vertical-scroll {
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 10px;
		background-color: $secondary-color;
	}

	&::-webkit-scrollbar {
		height: 10px;
		background-color: $secondary-color;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: $primary-color;
		&:hover {
			background-color: $primary-color;
		}
	}
}

$teacher-primary: #013e52;
$teacher-secondary-color: #d9ae8a;
$teacher-secondary-dark-color: #c19749;
$teacher-alternate-color: #fff1d6;
$app-medium-font: PPHatton-Medium;
$app-primary-medium-font: inter-medium;
$app-primary-regular-font: inter-regular;
$app-primary-semibold-font: inter-semibold;
$app-primary-bold-font: inter-bold;
$app-primary-extrabold-font: inter-extrabold;
$app-secondary-regular-font: times_new_roman;


