@import "variables.scss";

.totalBoxes {
  .column {
    &.custom {
      @media (max-width: 1199px) {
        width: 33%;
      }
    }
  }
  .totalBoxesInnerr {
    background-color: $teacher-primary;
    padding: 10px;
    border-radius: 5px;
    color: $white-color;

    p {
      font-size: 14px;
      min-height: 40px;
    }

    h5.ui.header {
      font-size: 16px;
      color: $white-color;
      min-height: 40px;
      margin: 10px 0 0;

      a {
        color: $white-color;
      }
    }
  }
}

.Admin {
  .lessonPlanPage {
    padding-left: 20px;
    .adminBookLesson {
      position: relative;
      .bookSideBar {
        top: 56px;
      }
    }
  }
  .filterDropdwon {
    display: flex !important;
    flex-wrap: wrap;

    .ui.dropdown {
      border: 1px solid $primary-color;
      padding: 10px;
      border-radius: 3px;
      margin-right: 20px;
      margin: 0 20px 20px 0;

      // width: 18%;
      &:last-child {
        margin-right: 0;
      }

      > .dropdown.icon {
        float: right;
        line-height: 20px;
      }

      .menu {
        width: 100% !important;

        &:after {
          left: 20px !important;
        }

        .item {
          color: $primary-color;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }

  .bookDetail {
    margin: 0 0 25px;

    img.ui.image {
      border-radius: 8px;
    }

    .ui.header {
      margin: 5px 0;
      color: $primary-color;
      font-size: 13px;
      font-weight: 100;
    }

    p {
      font-size: 12px;
      color: $gray-color;
    }
  }

  .filterBy {
    box-shadow: none !important;
    border: none !important;

    .item {
      padding: 7px 10px !important;

      &:before {
        display: none !important;
      }
    }
  }

  .booksResult {
    border-left: 1px solid $secondary-color;
    padding-left: 40px;

    .ui.items {
      .item {
        background-color: $secondary-color;
        border-radius: 5px;
        overflow: hidden;
        .ui.image {
          img {
            min-height: 130px;
            max-height: 130px;
            object-fit: cover;
          }
        }
        .content {
          padding: 9px 15px;
          position: relative;

          .header {
            display: inline-flex;
            justify-content: space-between;
            color: $primary-color;
          }

          .meta {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;
            font-size: 13px;
          }

          .description {
            font-size: 13px;
            margin-top: 5px;
          }

          .extra {
            font-size: 13px;
            margin-top: 5px;
            position: initial;

            .icons {
              position: absolute;
              right: 5px;
              top: 7px;
              margin: 0;
              display: flex;
              .checkboxClick {
                margin:1px 7px 0 0;
              }
            }
          }
        }
      }
    }
  }

  .lessonPlanTemplate {
    background-color: $secondary-color;
    color: $primary-color;
    text-align: center;
    border-radius: 5px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;

    p {
      font-size: 16px;
    }

    &.yourOwn {
      background-color: $primary-color;
      color: $white-color;
    }

    a {
      display: flex;
      height: 100%;
      width: 100%;
      color: $white-color;
      align-items: center;
      justify-content: center;
    }
  }

  h4.ui.header.bookName {
    color: $white-color;
    background-color: $primary-color;
    padding: 10px;
    border-radius: 5px;
  }

  .chapterBox {
    border: 1px solid $gray-color;
    padding: 15px;
    border-radius: 5px;
    height: 100%;
    overflow: auto;

    h4.ui.header {
      color: $primary-color;
    }

    h5.ui.header {
      margin: 15px 0 5px;
    }

    .contentDragDrop {
      border: 1px dashed $gray-color;
      height: 337px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
    }
  }

  .addMore {
    display: flex;
    align-items: center;
  }

  .searchResultFilter {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 5px;
    background-color: $secondary-color;

    .ui.vertical.menu {
      background-color: transparent;
    }
  }

  .performanceMatrix {
    h4.ui.header {
      margin-bottom: 10px;
    }

    .performanceMatrixInner {
      border: 1px solid $gray-color;
      padding: 15px;
      border-radius: 5px;
      .column {
        .column {
          &:last-child {
            .performanceMatrixInnerBox {
              &:before {
                display: none;
              }
            }
          }
        }
      }
      .performanceMatrixInnerBox {
        text-align: center;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: -15px;
          bottom: 0;
          background-color: $gray-color;
          width: 1px;
        }

        &.borderNone {
          &:before {
            display: none;
          }
        }

        h5.ui.header {
          font-size: 20px;
          margin-bottom: 10px;
        }

        p {
          font-size: 18px;
        }
      }
    }
  }

  .minHeight {
    height: calc(100vh - 95px);
  }

  .ui.fluid.input.left.icon {
    input {
      height: 45px;
    }
  }

  .searchHeader {
    .ui.radio.checkbox {
      input {
        &:checked {
          ~ label {
            &:before {
              background-color: $primary-color !important;
              border-color: $primary-color !important;
            }

            &:after {
              color: $white-color !important;
              content: "\e800" !important;
              font-size: 25px;
              left: -2px;
              top: 2px;
              width: 17px !important;
              height: 17px !important;
            }
          }
        }

        &:focus {
          ~ label {
            &:before {
              border-color: $secondary-color !important;
            }
          }
        }
      }

      label {
        &:before {
          width: 17px !important;
          height: 17px !important;
          border-radius: 3px;
        }

        &:after {
          background: none !important;
        }
      }
    }
  }

  .passingYear {
    .ui.dropdown {
      > .menu {
        height: 145px !important;
      }
    }
  }

  // Scan Book Page
  .scanBook {
    .scanBookInner {
      border: 1px dashed $gray-color;
      padding: 15px;
      .tagField {
        display: grid;
        gap: 32px;
        margin: 1px 0 0; 
        grid-template-columns: 1fr 1fr 1fr;
      }
      .bookCoverImg {
        .srv-validation-message {
          width: 125px;
          text-align: center;
        }
      }
      .scanBookInnerForm {
        width: 100%;
        padding: 0 0 20px 20px;
       
      }
      .uploadBtn {
        width: 120px;
        padding: 10px;
        overflow: hidden;
      }
    }

    .scanBookList {
      background-color: $secondary-color;
      padding: 10px;
      border-radius: 3px;
      color: $primary-color;
      position: relative;
      font-size: 16px;
      margin-bottom: 10px;
      display: inline-block;
      .bookIcon {
        color: $primary-color !important;
        margin-right: 10px;
      }

      .closeIcon {
        position: absolute;
        right: 2px;
        top: 10px;
      }
    }

    .coverImg {
      display: flex;
      align-items: flex-start;
      margin: 0 0 10px;

      div {
        position: relative;
        border-radius: 5px;
        border: 1px solid #e3e3e3;
        padding: 10px;

        img {
          max-width: 105px !important;
          height: 105px;
          object-fit: contain;
          min-width: 105px;
          margin: 0;
          border-radius: 0;
        }

        i.icon {
          position: absolute;
          top: -10px;
          right: -10px;
          background-color: #000;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          height: 20px;
          width: 20px;
          border-radius: 30px;
        }
      }
    }

    .uploadedImg {
      margin: 0 0 10px;

      img {
        max-width: 125px !important;
        height: 125px;
        object-fit: cover;
        min-width: 125px;
        margin: 10px 0 0;
      }
    }
  }


  .myBooks {
    padding-left: 0 !important;
    border: none !important;
  }

  .addTag {
    .bookPage {
      border: 1px solid $gray-color;
      padding: 15px;
      border-radius: 5px;

      .ui.header {
        text-align: center;
      }
    }
  }

  .resources {
    .ui.menu {
      display: inline-flex;
      margin: 10px 0 !important;

      .item {
        background-color: $secondary-color;
        color: $primary-color;
        padding: 12px 30px !important;

        &:first-child {
          border-radius: 5px 0 0 5px;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
        }

        &.active,
        &:hover {
          background-color: $primary-color !important;
          color: $white-color !important;
        }
      }
    }

    .ui.tab {
      padding: 0 !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  .status {
    p {
      padding-bottom: 10px;
    }

    .statusToggle {
      display: flex;
      align-items: center;

      span {
        margin-right: 15px;
      }
    }
  }

  .addTagsModal.ui.modal {
    background: transparent;

    > .header {
      background: rgba(255, 255, 255, 0.8);
    }

    > .content {
      background: rgba(255, 255, 255, 0.8);
    }

    > .actions {
      background: rgba(249, 250, 251, 0.8);
    }
  }

  .bookFlip {
    .bookFlipOuter {
      height: 510px !important;
      overflow: hidden;

      .innerPage {
        .bookHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;

          // margin: 0 43px 0 0;
          .left {
            display: flex;
            align-items: center;

            img.ui.image {
              margin: 0 10px 0 0;
              cursor: pointer;
            }

            i.icon {
              color: $teacher-primary;
              margin: -5px 0 0;
              cursor: pointer;
            }
          }

          .center {
            p {
              color: $teacher-primary;
              font-size: 11px;
              letter-spacing: 0.26px;
              line-height: 22px;
              text-align: center;
              text-transform: uppercase;

              @media (max-width: 1199px) {
                max-width: 90px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
          }

          .right {
            display: flex;
            align-items: center;

            img.ui.image {
              margin: 0 0 0 10px;
              cursor: pointer;

              &.menuIcon {
                width: 22px;
              }
            }
          }
        }

        .bookContentOuter {
          margin: 15px 0 0;
          // padding: 0 25px 0 0;
          position: relative;

          .editDropDown {
            position: absolute;
            right: 0;
            top: 0;

            .menu {
              width: 130px;
              border: 1px solid rgba(1, 62, 82, 0.38);
              border-radius: 5px;
              background-color: #fffcf6;
              box-shadow: 0 23px 45px 11px rgba(0, 0, 0, 0.1);

              .item {
                border-top: 1px solid rgba(1, 62, 82, 0.38);
                text-transform: uppercase;
                padding: 5px 10px !important;
                color: $teacher-primary !important;

                &:first-child {
                  border: none;
                }

                span {
                  margin: 0;
                  color: $teacher-primary !important;
                  font-size: 10px !important;
                  font-family: $app-primary-medium-font;
                  letter-spacing: 0.24px;
                  line-height: 26px;
                }
              }
            }
          }

          .blockLines {
            position: absolute;
            right: -30px;
            top: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            cursor: pointer;

            .innerLine {
              width: 2px;
              height: 11px;
              background-color: $teacher-primary;
              flex: auto;
              margin: 0 0 10px;
              position: relative;

              &:last-child {
                margin: 0;
              }

              &.active {
                background-color: $teacher-secondary-color;
              }

              .ui.dropdown {
                position: absolute;
                inset: 0;
              }
            }
          }
        }

        // .bookContent {
        //   .bookContentInner {
        //     border: 0.5px solid rgba(0, 62, 82, 0.33);
        //     // border-bottom: none;
        //     background-color: #ffffff;
        //     height: calc(100vh - 122px);
        //     // padding: 37px 25px 5px 41px;
        //     padding: 37px 15px 10px 15px;
        //     position: relative;
        //     overflow: auto;
        //     -ms-overflow-style: none;
        //     scrollbar-width: none;

        //     &::-webkit-scrollbar {
        //       width: 0;
        //     }

        //     .btns {
        //       margin: 13px 0 0;

        //       .ui.button {
        //         margin: 0 17px 17px 0 !important;
        //         width: 120px;

        //         &:last-child {
        //           margin-right: 0 !important;
        //         }
        //       }
        //     }

        //     .ui.dropdown.bookDetailPopup {
        //       &.topleft {
        //         position: absolute;
        //         top: 10px;
        //         left: 10px;

        //         i.icon {
        //           color: $teacher-secondary-color;
        //           font-size: 10px;
        //         }
        //       }

        //       .text {
        //         background-color: rgba(217, 174, 138, 0.25);
        //         cursor: pointer;
        //         margin: 0 5px 0 0;
        //         padding: 0 3px;
        //       }

        //       .menu {
        //         white-space: initial;
        //         width: 350px;
        //         border: 0.5px solid rgba(1, 62, 82, 0.38);
        //         background-color: #fffcf6;
        //         box-shadow: 0 10px 47px 1px rgba(0, 0, 0, 0.31);
        //         min-width: auto;
        //         padding: 15px;

        //         @media (max-width: 1300px) {
        //           width: 300px;
        //         }

        //         .tags {
        //           color: $teacher-primary;
        //           font-size: 11px;
        //           font-family: $app-primary-bold-font;
        //           letter-spacing: 0.26px;
        //           line-height: 25px;
        //           white-space: initial;
        //         }

        //         p {
        //           color: $black-color;
        //           font-size: 12px;
        //           line-height: 24px;
        //           font-family: $app-primary-regular-font;
        //           padding: 0;
        //           white-space: initial;
        //         }

        //         a {
        //           color: $teacher-secondary-dark-color;
        //           font-size: 11px;
        //           font-family: $app-primary-bold-font;
        //           letter-spacing: 0.26px;
        //           line-height: 25px;
        //           display: inline-block;
        //           margin: 5px 10px 0 0;
        //           white-space: initial;
        //         }
        //       }
        //     }

        //     p {
        //       font-family: $app-secondary-regular-font;
        //       color: $black-color;
        //       font-size: 16px;
        //       letter-spacing: 0.38px;
        //       line-height: 24px;
        //       padding: 0 0 10px;

        //       @media (max-width: 1300px) {
        //         font-size: 14px;
        //       }
        //     }
        //   }
        // }

        .pagination {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 0 0;

          // margin: 0 44.5px 0 0;
          // border-top: 0.5px solid rgba(0, 62, 82, 0.33);
          i.icon {
            font-size: 15px;
            color: $teacher-secondary-color;
            text-align: left;
            margin: -3px 0 0;
            cursor: pointer;

            &:last-child {
              text-align: right;
            }
          }

          p {
            color: $teacher-primary;
            font-size: 11px;
            letter-spacing: 0.26px;
            line-height: 22px;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
    }

    .stf__parent {
      // height: 500px!important;
      // overflow: hidden;
      .demoPage {
        height: 500px !important;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    img.ui.image {
      height: 300px;
      object-fit: cover;
      margin: 0 auto;
    }
  }

  .profileView {
    .profileViewHeader {
      display: flex;
      padding: 50px 15px 15px 15px;
      margin: -15px -15px 0;
      position: relative;
      justify-content: center;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background-color: $primary-color;
        height: 150px;
      }

      .teacherProfileText {
        z-index: 999;
        color: #fff;
        text-align: center;
      }

      .header {
        color: #fff;
        margin: 0 !important;

        span {
          position: absolute;
        }
      }

      p {
        color: #fff;

        // margin: 5px 0 0 !important;
        span {
          color: #ddd;
        }
      }

      .ProfileEdit {
        position: absolute;
        top: 50%;
        right: 15px;
        font-size: 19px;
        transform: translateY(-50%);
        z-index: 2;

        .ui.button {
          border-radius: 50% !important;
          height: 45px;
          width: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $primary-color;
          font-size: 18px;
          background-color: #fff !important;
        }
      }

      .profileImgOuter {
        margin-right: 30px;
        text-align: center;

        i.icon {
          background-color: $primary-color;
          height: 30px;
          width: 30px;
          text-align: center;
          line-height: 30px;
          text-align: center;
          border-radius: 5px;
          color: #fff;
          border-radius: 50%;
          font-size: 12px;
          cursor: pointer;
        }

        input[type="file"] {
          display: none;
        }

        .custom-file-upload {
          cursor: pointer;
        }
      }

      .profileImg {
        position: relative;
        height: 145px;
        width: 145px;
        min-width: 145px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto 15px;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .profileViewHeaderDesc {
        z-index: 1;
        width: 100%;
        padding: 25px 0 0;

        &.schoolHeader {
          padding: 55px 0 0;
        }

        .blue {
          color: $primary-color;

          span {
            color: #ddd;
          }
        }

        .ui.horizontal.list.basicInfo {
          .item {
            display: inline-flex;
            color: #fff;

            i.icon {
              color: #ddd;
            }
          }
        }

        .ui.horizontal.list.gradePlan {
          display: flex;
          flex-wrap: wrap;
          margin: 10px 0 0;
          justify-content: flex-end;

          .item {
            margin: 0 30px 0 0;
            display: flex;
            align-items: center;

            .ui.avatar.image {
              width: 50px;
              height: 50px;
              background-color: $primary-color;
              padding: 5px;
            }

            .content {
              display: flex;
              align-items: center;

              span {
                width: 60px;
                height: 60px;
                background-color: $primary-color;
                border-radius: 50%;
                line-height: 60px;
                color: #fff;
                text-align: center;
                margin: 0 10px 0 0;
                font-family: "latoblack" !important;
                font-size: 18px;
              }

              .header {
                font-size: 22px;
                color: $primary-color;

                // background-color: $primary-color;
                // color: #ddd;
                // width: 100px;
                // text-align: center;
                // height: 100px;
                // border-radius: 50%;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                span {
                  font-size: 25px;
                  color: #fff;
                  line-height: 30px;
                }
              }
            }
          }
        }
      }
    }

    .profileViewBody {
      p {
        margin: 5px 0 !important;

        span {
          color: #999;
        }
      }
    }
  }

  .buyPlan {
    padding: 15px;
    border-radius: 5px;
    text-align: center;
    border: 2px solid #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

    &:hover {
      border-color: $primary-color;
    }

    h4.ui.header {
      color: $primary-color;
    }

    p {
      color: #999;
      font-size: 18px;

      .dollor {
        color: $primary-color;
        font-size: 14px;
      }

      .value {
        color: $primary-color;
        font-size: 25px;
      }
    }

    .primaryBtn.ui.button {
      width: 100%;
      margin-top: 60px;
    }
  }

  .addGradeInput {
    .field {
      label {
        color: rgba(0, 0, 0, 0.87) !important;
      }

      &.error {
        .srv-validation-message {
          color: #f00;
        }
      }
    }
  }

  .setImg {
    .setImgInner {
      height: 120px;
      width: 120px;
      margin: 0 0 10px;
      border: 1px solid #e3e3e3;
      padding: 5px;
      border-radius: 5px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      &.bookAdminImg {
        margin: 0;
      }
      i.close.icon {
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: #253b49;
        color: #fff;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        font-size: 12px;
        line-height: 20px;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .ui.button {
      position: relative;

      input {
        position: absolute;
        inset: 0;
        opacity: 0;
        left: -88px;
        cursor: pointer;
        width: auto;
      }
    }
  }

  .form_editor {
    overflow: auto;
  }

  .ui.basic.table.lessonDetail {
    tr {
      td {
        border-top: none !important;
      }
    }
  }
}

.myLibrary {
  padding-top: 50px;
}

.featureReading.ui.card {
  border: none;
  box-shadow: none;
  height: 100%;
  .content {
    background-color: #f9f9f9;
    &:first-child {
      background-color: #f2f2f2;
      border: none;
      max-height: 40px;
    }
    .header {
      font-size: 15px;
    }
  }
}

.featureReadingBook {
  flex-wrap: nowrap !important;
  overflow: auto;
  padding: 0 20px 60px 20px !important;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  > .column {
    min-width: 50% !important;
  }
}

.blockEditor {
  padding: 37px 15px 5px 15px;
  border: 0.5px solid rgba(0, 62, 82, 0.33);
  background-color: #ffffff;
  height: calc(100vh - 122px);
  overflow: auto;
  .rdw-embedded-modal {
    @media (min-width: 1293px) {
      right: 0;
      left: initial;
    }
  }
  .rdw-link-modal {
    @media (min-width: 1208px) {
      right: 0;
      left: initial;
    }
  }
  // text-align: center;
  // .title {
  //   color: $primary-color;
  //   font-size: 12px;
  //   letter-spacing: 0.2px;
  //   line-height: 10.8px;
  //   text-align: center;
  //   position: relative;
  //   text-transform: uppercase;
  // }

  h3.ui.header {
    color: #003e52;
    font-family: $app-medium-font;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    position: relative;
    margin: 0;
    display: inline-block;
    background-color: #fff;
    padding: 0 25px;
    width: 100%;

    // &::before {
    //   content: "";
    //   position: absolute;
    //   left: 0;
    //   width: 35%;
    //   height: 0.9px;
    //   background-color: #013e52;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   opacity: 0.19;
    // }

    // &::after {
    //   content: "";
    //   position: absolute;
    //   right: 0;
    //   width: 35%;
    //   height: 0.9px;
    //   background-color: #013e52;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   opacity: 0.19;
    // }
  }

  h5.ui.header {
    color: #003e52;
    font-size: 15px;
    letter-spacing: 0.31px;
    line-height: 23.6px;
    position: relative;
    margin: 25px 0 5px;
    font-family: $app-primary-semibold-font;
    img.ui.image {
      width: 16px;
      margin: -1px 0 0 4px !important;
      cursor: pointer;
      position: absolute;
      right: 0;
    }
  }

  .addNotes {
    border: 1px solid #e3e3e3;
    padding: 5px;
    margin: 10px 0px;

    .DraftEditor-root,
    .DraftEditor-editorContainer {
      height: auto !important;
    }
  }
}

.standardCheckboxButton {
  display: flex;
  grid-gap: 8px;
  align-items: center;

  .field {
    margin: 0 !important;
    input {
      margin: 100%;
    }
  }
}

.createNewEngegement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  h2.ui.header {
    color: $teacher-primary;
    font-family: $app-medium-font !important;
    font-size: 23px;
    line-height: 29px;
    text-align: center;
    margin: 0 0 14px;
  }
}
.engegementFirst {
  position: relative;
  height: 100%;
  overflow: auto;
  padding: 43px 17px 25px 23px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  h2.ui.header {
    color: $teacher-primary;
    font-family: $app-medium-font !important;
    font-size: 23px;
    line-height: 29px;
    text-align: center;
    margin: 0 0 5px;
  }
  .engagementIndex {
    max-width: 251px;
    margin: 0 auto 20px;
    .ui.input {
      width: 100%;
    }
  }
  .ui.form {
    padding: 10px 0;
    &.addCoachingForm {
      .field {
        margin: 0 0 23px !important;
      }
    }
    .field {
      margin: 0;
      p.question {
        color: $teacher-primary !important;
        font-family: $app-primary-regular-font;
        font-size: 14px !important;
        letter-spacing: 0.33px;
        line-height: 17px;
        padding: 0 0 14px !important;
      }
      .days {
        color: #000000;
        font-family: $app-secondary-regular-font;
        font-size: 16px !important;
        letter-spacing: 0.38px;
        line-height: 27px;
      }
      .ui.radio.checkbox {
        margin: 0 0 9px;
        &.shortRadio {
          .days {
            display: none;
          }
          &.checked {
            .days {
              display: block;
            }
          }
        }
        label {
          color: $teacher-primary !important;
          font-family: $app-primary-regular-font;
          font-size: 14px;
          letter-spacing: 0.33px;
          line-height: 18px;
          // opacity: 0.41;
          div {
            display: flex;
            > span {
              margin-left: 5px;
            }
          }
          span {
            font-family: $app-primary-bold-font;
          }
          &::before {
            height: 16px;
            width: 16px;
            border: 2px solid $teacher-primary;
          }
        }
        input {
          &:focus {
            ~ label {
              &::before {
                border-color: $primary-color !important;
              }
            }
          }
        }
        &.checked {
          label {
            opacity: 1;
            &::after {
              background-color: $teacher-primary;
              height: 16px;
              width: 16px;
            }
          }
        }
      }
      textarea {
        border: 1px solid rgba(0, 0, 0, 0.18);
        border-radius: 10px;
        min-height: 82px;
        &::placeholder {
          color: $teacher-primary !important;
          font-family: $app-primary-regular-font;
          font-size: 14px;
          letter-spacing: 0.33px;
          line-height: 18px;
        }
      }
      .addCoachingLink {
        color: #013e52;
        font-family: $app-primary-bold-font;
        font-size: 14px;
        letter-spacing: 0.33px;
        line-height: 17px;
      }
    }
    .fields {
      margin: 0 0 15px;
      gap: 14px 0;
      justify-content: space-between;
      flex-wrap: wrap;
      &.firstGroup {
        margin-top: 22px;
      }
      &.pageNumber {
        margin: 14px 0 50px;
        justify-content: flex-start;
        gap: 14px;
        .field {
          width: auto!important;
          .ui.input {
            input {
              padding: 8px 3px;
              text-align: center;
              height: 48px;
              width: 55px;
              border: 1px solid rgba(0, 0, 0, 0.18);
              border-radius: 10px;
            }
          }
          p {
            color: #000000;
            font-family: $app-primary-regular-font;
            font-size: 12px;
            letter-spacing: 0.28px;
            line-height: 26px;
            padding: 0;
            display: flex;
            align-items: center;
            height: 100%;
          }
        }
      }
      .field {
        padding: 0;
        width: 49%!important;
        @media (max-width: 1199px) {
          width: 100%!important;
        }
        .ui.selection.dropdown {
          height: 63px;
          padding: 19px 23px;
          border: 1px solid rgba(0, 0, 0, 0.18);
          border-radius: 10px;

          &.multiple {
            padding: 12px 27px 12px 17px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            height: auto;
            min-height: 63px;
          }
          .text {
            color: #000000;
            font-family: $app-primary-regular-font;
            font-size: 12px;
            letter-spacing: 0.28px;
            line-height: 26px;
          }
          i.icon.down {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 8px;
            margin: 0;
            color: $teacher-secondary-dark-color;
          }
        }
        &.gradeSelect {
          .field {
            width: 100%!important;
          }
        }
      }
    }
    .actions {
      gap: 29px;
      display: flex;
      @media (max-width: 1199px) {
        flex-direction: column;
      }
      &.addCoachActions {
        margin: 40px 0 0;
      }
    }
    .coachLists {
      display: flex;
      flex-wrap: wrap;
      gap: 10px 30px;
      margin: 0 0 31px;
      span {
        color: $teacher-primary;
        font-family: $app-primary-regular-font;
        font-size: 12px;
        letter-spacing: 0.28px;
        line-height: 17px;
        position: relative;
        i.icon {
          position: absolute;
          right: -21px;
          top: -4px;
          color: $teacher-primary;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
}
.ui.input.engagementIndex {
  width: 88px;
  position: absolute;
  top: 14px;
  right: 68px;
}
.changePassword {
  .field {
    position: relative;
    &.error {
      .ui.input {
        input {
          background: #fff6f6;
          border-color: #e0b4b4;
          color: #9f3a38;
        }
      }
    }

    i.icon {
      position: absolute;
      right: 5px;
      top: 32px;
      cursor: pointer;
    }
  }
}

.statusToggle {
  display: flex;
  align-items: center;
  gap: 10px;
}
.statusToggles {
  margin: 9px 0px;
  padding: 0;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.aleeCoachModal {
  .modal-content {
    padding: 0 !important;
    .aleeCoachPage {
      padding: 25px;
      .liberaryHeading {
        font-weight: 400;
      }
    }
  }
}
.signUpWrapper {
  background-color: $teacher-primary;
  width: 100%;
  .signUpNew {
    position: relative;
    display: flex;
    align-items: center;
    // justify-content: center;
    min-height: 100vh;
    flex-direction: column;
    padding: 0 15px 30px;
    max-width: 1500.48px;
    margin: 0 auto;
    img.ui.image.logo {
      position: absolute;
      height: 34px;
      width: 148px;
      top: 28px;
      left: 31px;
    }
    .signUpNewInner {
      background-color: #fffcf8;
      max-width: 958px;
      width: 100%;
      padding: 40px 20px 40px 20px;
      border-radius: 25px;
      margin: 150px 0 20px;
      @media screen and (max-width: 767px) {
        margin: 90px 0 20px;
      }
      h2.ui.header {
        color: $teacher-primary;
        font-family: $app-medium-font;
        font-size: 32px;
        font-weight: 500;
        line-height: 50px;
        text-align: center;
      }
      .ui.form.signUpForm {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 10px 0 0;
        @media screen and (max-width: 767px) {
          margin: -20px 0 0;
        }
        .formGroup {
          width: 47%;
          margin: 0 0 21px;
          &:nth-child(2n + 1) {
            .field {
              label {
                color: #3f3f3f;
                left: -6px;
                position: relative;
                word-spacing: 1px;
              }
            }
          }
          .field {
            &.error {
              .ui.input {
                input {
                  background: #fff6f6;
                  border-color: #e0b4b4;
                  color: #9f3a38;
                }
              }
              .ui.selection.dropdown {
                background: #fff6f6;
                border-color: #e0b4b4;
                color: #9f3a38;
              }
            }
            label {
              color: #000;
              font-size: 11px;
              letter-spacing: 0.25px;
              line-height: 23px;
              text-transform: uppercase;
              margin: 0px 2px 6px;
              font-weight: normal;
              font-family: $app-primary-regular-font;
            }

            .ui.input {
              input {
                height: 58px;
                border: 1px solid rgba(0, 0, 0, 0.18);
                border-radius: 10px;
                background-color: #ffffff;
              }
            }
            .ui.selection.dropdown {
              height: 58px;
              border: 1px solid rgba(0, 0, 0, 0.18);
              border-radius: 10px;
              background-color: #ffffff;
              display: flex;
              align-items: center;
              padding: 17px 48px 17px 12px;
              .text {
                color: #000000;
                font-size: 14px;
                letter-spacing: 0.33px;
                line-height: 26px;
                font-family: $app-primary-regular-font;
                position: initial;
              }
              i.icon {
                color: #bf964a;
                position: absolute;
                top: 24px;
                right: 23px;
              }
              .menu {
                border: 1px solid rgba(0, 0, 0, 0.18);
                min-width: calc(100% + 1px);
              }
            }
          }
        }
      }
      .signUpBtn {
        height: 57px;
        background-color: #bf964a;
        color: #ffffff;
        font-family: $app-primary-regular-font;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.38px;
        line-height: 15px;
        text-align: center;
        margin: 6px 0 0;
        border-radius: 0;
        line-height: 34px;
        width: 100%;
      }
      &.verifyEmail {
        height: 619px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h2.ui.header {
          margin: -18px 0px 0 -2px;
        }
        p {
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0.38px;
          line-height: 29px;
          text-align: center;
          max-width: 668px;
          color: #013e52;
          font-family: $app-primary-regular-font;
          margin: 5px auto 0;
          span {
            color: #c19749;
            font-family: $app-primary-semibold-font;
            cursor: pointer;
          }
        }
      }
      &.completePayment {
        padding: 49px 61px 69px 59px;
        h2.ui.header {
          font-size: 35px;
        }
        .ui.form {
          margin: 34px 0 0;
        }

        h3 {
          color: #013e52;
        }

        h4 {
          color: #013e52;
        }

        h4.ui.header {
          color: #000000;
          font-family: $app-primary-semibold-font;
          font-size: 15px;
          letter-spacing: 0.36px;
          line-height: 26px;
          margin: 0;
          &.cardInformation {
            padding: 21px 0 15px;
          }
          &.billingInformation {
            padding: 21px 0 18px;
          }
        }
        .ui.selection.dropdown {
          height: 49px;
          border: 1px solid rgba(0, 0, 0, 0.18);
          border-radius: 6px;
          background-color: #ffffff;
          .text {
            color: #000000;
            font-family: $app-primary-regular-font;
            font-size: 10px;
            letter-spacing: 0.24px;
            line-height: 26px;
            text-transform: uppercase;
          }
          i.icon {
            color: #bf964a;
            position: absolute;
            top: 17px;
            right: 16px;
          }
          .menu {
            border: 1px solid rgba(0, 0, 0, 0.18);
            min-width: calc(100% + 1px);
          }
        }
        .ui.input {
          input {
            height: 49px;
            border: 1px solid rgba(0, 0, 0, 0.18);
            border-radius: 6px;
            background-color: #ffffff;
            &::placeholder {
              color: #000000;
              font-family: $app-primary-regular-font;
              font-size: 10px;
              letter-spacing: 0.24px;
              line-height: 26px;
              text-transform: uppercase;
            }
          }
        }
        .payMethod {
          width: 100%;
          display: flex;
          align-items: center;
          position: relative;
          .srv-validation-message {
            position: absolute;
            left: 0;
            top: 100%;
          }
          .field {
            width: 26.8%;
            &.addPromoCode {
              position: relative;
              .field {
                width: 100%;
              }
            }
            &:first-child {
              width: 31.7%;
            }
            &:nth-of-type(2) {
              width: 41.5%;
            }
            margin: 0;
            .ui.radio.checkbox {
              margin: 5px 0 0;
              label {
                color: #000000;
                font-family: $app-primary-regular-font;
                font-size: 14px;
                letter-spacing: 0.33px;
                line-height: 20px;
                &::after {
                  display: none;
                }
                &::before {
                  height: 17px;
                  width: 17px;
                  border: 1px solid #013e52;
                }
              }
            }
          }
        }
        .numberName {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .field {
            width: 47%;
            margin: 0 0 13px;
            position: relative;
            &.error {
              margin: 0 0 33px;
              .srv-validation-message {
                position: absolute;
                top: 100%;
              }
            }
          }
          &.expirySecCode {
            width: 47%;
            .expiryDate {
              height: 49px;
              border: 1px solid rgba(0, 0, 0, 0.18);
              border-radius: 6px;
              background-color: #ffffff;
              &::placeholder {
                color: #000000;
                font-family: $app-primary-regular-font;
                font-size: 10px;
                letter-spacing: 0.24px;
                line-height: 26px;
                text-transform: uppercase;
              }
            }
          }
        }
        .totalDue {
          color: #000000;
          font-family: $app-primary-semibold-font;
          font-size: 15px;
          letter-spacing: 0.36px;
          line-height: 26px;
          margin: 24px 0 17px;
        }
      }
    }
    .alreadyAccount {
      height: 25px;
      opacity: 0.49;
      color: #fffcf8;
      font-family: $app-primary-regular-font;
      font-size: 12px;
      letter-spacing: -0.2px;
      line-height: 21px;
      text-align: center;
      a {
        color: #fffcf8;
        text-decoration: underline;
      }
    }
  }
}

.myTeachers {
  h2.ui.header.teacherHeading {
    color: $teacher-primary;
    font-family: $app-medium-font;
    font-size: 34px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 42px;
    margin: 19px 0 29px;
  }
  .teacherInfo {
    max-height: calc(100vh - 176px);
    overflow: auto;
    .teacherInfoInner {
      display: flex;
      align-items: center;
      padding: 0 0 0 3px;
      gap: 13px 6.3%;
      flex-wrap: wrap;
      .teacherInfoList {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 28%;
        padding: 0px 7px 11px 4px;
        border-bottom: 1px solid rgba(1, 62, 82, 0.3);
        .name {
          color: $teacher-primary;
          font-family: $app-primary-medium-font;
          font-size: 14px;
          letter-spacing: 0.33px;
          line-height: 19px;
          width: 38%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .lesson {
          padding: 2px 0 0;
        }
        .grade {
          color: $teacher-primary;
          font-family: $app-primary-medium-font;
          font-size: 11px;
          letter-spacing: 0.26px;
          line-height: 19px;
          width: 20%;
          text-align: right;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

.addCategoriesToCoach {
  position: relative;
  i.icon.close {
    position: absolute;
    right: 5px;
    bottom: 12px;
  }
}
.amountPercentag {
  display: flex;
  align-items: center;
  gap: 15px;
  height: 100%;
  .commonToggle.percentage {
    label {
      &::before {
        background-color: $primary-color !important;
      }
    }
  }
}
.rolesPermissions {
  .modulesCheckBox {
    border-top: 1px solid #e3e3e3;
    padding: 10px 0 0;
    &:first-child {
      border: none;
    }
    .ui.checkbox {
      label {
        font-family: $app-primary-semibold-font;
      }
    }
  }
  .screenCheckBox {
    padding: 0 0 0 27px;
  }
  .actionCheckBox {
    padding: 0 0 0 54px;
  }
}

.text-center {
  text-align: center;
}
.subscriptionSelectDuration {
  .customSelectDropdownBox {
    img {
      top: 40px !important;
    }
  }
}
.addAleeCoachModal.ui.fullscreen.scrolling.modal {
  left: initial!important;
}
.clndr-floating {
  bottom: 50px;
  top: initial;
}