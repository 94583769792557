@import "variables.scss";

body {
  font-family: $app-primary-regular-font  !important;
}

html {
  height: auto !important;
}

* {
  box-sizing: border-box !important;
}

.ui.dimmer {
  z-index: 1040 !important;
  justify-content: center !important;
}

.teacher-secondary-color {
  color: $teacher-secondary-color;

  &:hover {
    color: $teacher-secondary-color;
  }
}

.primary-color {
  color: $teacher-primary;

  &:hover {
    color: $teacher-primary;
  }
}


.PrimaryOutlineBtn.ui.button {
  background-color: #fff;
  color: $teacher-secondary-dark-color;
  font-size: 12px;
  letter-spacing: 0.28px;
  line-height: 15px;
  text-align: center;
  border-radius: 0;
  padding: 10px 20px;
  border: 1px solid $teacher-secondary-dark-color  !important;
  font-family: $app-primary-bold-font;
  min-width: 170px;

  &:hover {
    background-color: $teacher-secondary-dark-color  !important;
    color: #fff !important;
  }
}

.PrimaryFilledBtn.ui.button {
  color: #fff;
  background-color: $teacher-secondary-dark-color;
  font-size: 12px;
  letter-spacing: 0.28px;
  line-height: 15px;
  text-align: center;
  border-radius: 0;
  padding: 10px 20px;
  border: 1px solid $teacher-secondary-dark-color  !important;
  font-family: $app-primary-bold-font;
  min-width: 170px;

  &:hover {
    color: $teacher-secondary-dark-color  !important;
    background-color: #fff !important;
  }
}

.primaryBtn.ui.button {
  color: #fff;
  background-color: $teacher-secondary-dark-color;
  font-size: 12px;
  letter-spacing: 0.28px;
  line-height: 15px;
  text-align: center;
  border-radius: 0;
  padding: 10px 20px;
  border: 1px solid $teacher-secondary-dark-color  !important;
  font-family: $app-primary-bold-font;
  cursor: pointer !important;

  &:hover {
    color: $teacher-secondary-dark-color  !important;
    background-color: #fff !important;
  }

  &:active,
  &:focus {
    color: $teacher-secondary-dark-color  !important;
    background-color: #fff !important;
  }
}

.secondaryBtn.ui.button {
  background-color: #fff;
  color: $teacher-secondary-dark-color;
  font-size: 12px;
  letter-spacing: 0.28px;
  line-height: 15px;
  text-align: center;
  border-radius: 0;
  padding: 10px 20px;
  border: 1px solid $teacher-secondary-dark-color  !important;
  font-family: $app-primary-bold-font;
  cursor: pointer !important;

  &:hover {
    background-color: $teacher-secondary-dark-color  !important;
    color: #fff !important;
  }

  &:active,
  &:focus {
    background-color: $teacher-secondary-dark-color  !important;
    color: #fff !important;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {

    .secondaryBtn.ui.button,
    .primaryBtn.ui.button {
      font-family: $app-primary-regular-font;
    }
  }
}

.d-flex {
  display: flex;
}

.customSelectDropdownBox {
  position: relative;
  width: 100%;
  padding-left: 0.5em;
  padding-right: 0.5em;

  img.ui.image.downArrow {
    position: absolute;
    right: 20px;
    // bottom: 18px;
    top: 56px;
    margin: 0 !important;
    z-index: 2;
    width: 11.5px;
    height: 6.5px;
    opacity: 0.5;
  }

  .customSelectDropdown {
    .ui.selection.dropdown {
      input {
        top: 50%;
        transform: translateY(-50%);
        padding: 20px 35px 20px 19px;
      }
    }

    &.error {
      .ui.dropdown {
        .text {
          background-color: transparent !important;
        }
      }
    }
  }
}

.commonToggle {
  .ui.toggle.checkbox {
    input {
      &:focus {
        &:checked {
          ~label {
            &:before {
              background-color: $teacher-primary  !important;
            }
          }
        }
      }
    }

    &.checked {
      label {
        &:before {
          background-color: $teacher-primary  !important;
        }
      }
    }

    label {
      z-index: 0;
    }
  }
}

.ui.checkbox {
  &:not(.toggle) {
    input {
      &:checked {
        ~label {
          &:before {
            background-color: $primary-color  !important;
            border-color: $primary-color  !important;
          }

          &:after {
            color: $white-color  !important;
            z-index: 1 !important;
          }
        }
      }

      // &:focus {
      //   ~label {
      //     &:before {
      //       border-color: $secondary-color  !important;
      //     }
      //   }
      // }
    }

    label {
      &:after {
        z-index: 1 !important;
        background-color: transparent !important;
      }
    }
  }
}

.commonTableDropdown {
  tbody {
    display: block;
    max-height: 400px;
    overflow: auto;
  }

  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.Admin {
  body {
    position: relative;
    background-color: $secondary-color  !important;
  }

  .ui.header {
    font-weight: normal;
    font-family: $app-primary-medium-font;
  }

  p {
    margin-bottom: 0 !important;
  }

  a {
    &:hover {
      transform: none !important;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .w-100 {
    width: 100%;
  }

  .textNowrap {
    white-space: nowrap;
  }

  .commonHeading.ui.header {
    font-size: 22px;
    color: $primary-color;
  }

  .common-shadow {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 5px;
    min-height: calc(100vh - 95px);
    background-color: $white-color;
  }

  .blueDivider {
    border-top-color: $primary-color  !important;
  }

  .modal {
    left: initial !important;
    top: initial !important;
    bottom: initial !important;
    right: initial !important;
    height: auto !important;
  }

  .commonTable {
    overflow: auto;
  }

  .field {
    label {
      font-weight: 100 !important;
    }

    .ui.input {
      input {
        height: 36px;

        &:focus {
          border-color: $primary-color  !important;
        }
      }
    }

    textarea {
      &:focus {
        border-color: $primary-color  !important;
      }
    }
  }

  .addBtnInput {
    .ui.button {
      background-color: $primary-color;
      color: $white-color;

      &:hover,
      &:active {
        background-color: $primary-color;
        color: $white-color;
      }
    }
  }


  .commonToggle {
    .ui.toggle.checkbox {
      input {
        &:focus {
          &:checked {
            ~label {
              &:before {
                background-color: $primary-color  !important;
              }
            }
          }
        }
      }

      &.checked {
        label {
          &:before {
            background-color: $primary-color  !important;
          }
        }
      }
    }
  }

  .flexible-modal {
    padding: 15px;
    overflow: auto;
  }

  .flexible-modal {
    position: absolute;
    z-index: 1;
    border: 1px solid #ccc;
    background: white;
  }

  .flexible-modal-mask {
    position: fixed;
    height: 100%;
    background: rgba(255, 255, 255, 0) !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .flexible-modal-resizer {
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: se-resize;
    margin: 5px;
    border-bottom: solid 2px #333;
    border-right: solid 2px #333;
  }

  .flexible-modal-drag-area {
    background: rgba(255, 255, 255, 0.2) !important;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: move;
  }

  .flexible-modal-drag-area-left,
  .flexible-modal-drag-area-bottom,
  .flexible-modal-drag-area-right {
    display: none;
  }

  .sideBorder {
    border: 1px solid $primary-color  !important;
  }

  //   cursor: pointer !important;
  // }

  .field {
    position: relative;

    label {
      color: rgba(0, 0, 0, 0.87) !important;
    }

    .srv-validation-message {
      position: absolute;
      top: calc(100% + 4px);
      font-size: 12px;
      color: #f00;
      line-height: 12px;
    }

    &.error {
      .ui.input {
        input {
          background: #fff6f6;
          border-color: #e0b4b4;
          color: #9f3a38;
        }
      }
    }
  }

  .loginPassword {
    position: relative;
    padding-right: 30px !important;

    .passwordIcon {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
  }

  .password {
    padding-right: 30px !important;
  }

  .Headernotification {
    .content {
      .ui.feed {
        max-height: 300px;
        overflow: auto;
        overflow-x: hidden;
      }
    }
  }
}

.App {
  &.Admin {
    .main-container {
      // padding: 75px 15px 15px 170px;
      // transition: all 0.5s ease-out;

      padding: 20px 15px 15px 170px;
      transition: all 0.5s ease-out;
      overflow: auto;
      max-height: calc(100vh - 55px);
      margin: 55px 0 0;

      &.isFullEngOpen {
        height: 100vh;
        overflow: hidden;
      }

      @media (max-width: 1199px) {
        padding: 20px 15px 15px 80px;
      }
    }

    &.menuCollapse {
      .main-container {
        padding: 20px 15px 15px 76px;
        transition: all 0.5s ease-out;

        @media (max-width: 1199px) {
          padding: 20px 15px 15px 165px;
        }
      }

      .fullEngagement {
        left: 58px;
      }

      .Header {
        left: 60px;
        transition: all 0.5s ease-out;

        @media (max-width: 1199px) {
          left: 150px;
        }
      }
    }
  }

  &.Teacher,
  &.School,
  &.District {
    .main-container {
      padding: 0 0 0 150px;
      transition: all 0.5s ease-out;

      @media (max-width: 1200px) {
        padding: 0 0 0 58px;
      }

      .main-page {
        padding: 15px 45px 15px 62px;
        // background-color: #fbfbfb;
        background-color: #fff;
        height: 100vh;
        overflow: auto;
        transition: all 0.5s ease-out;
        position: relative;

        &.teacher-navbar {
          padding-top: 0 !important;
        }
      }
    }

    &.menuCollapse {
      .Header {
        .menuBar {
          left: 170px;
        }
      }

      .main-container {
        padding: 0 0 0 58px;
        transition: all 0.5s ease-out;

        @media (max-width: 1200px) {
          padding: 0 0 0 150px;
        }
      }

      .fullEngagement {
        left: 58px;
      }

      .Header {
        .menuBar {
          @media (min-width: 1199px) {
            left: 80px;
            transition: all 0.5s ease-out;
          }
        }
      }
    }
  }
}

.Teacher,
.School,
.District {
  body {
    font-family: $app-primary-regular-font  !important;
    position: relative;
  }

  p {
    font-size: 13px;
    margin-bottom: 0 !important;
    text-align: left;
  }

  a {
    &:hover {
      transform: none !important;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .d-none {
    display: none !important;
  }

  .mr-15 {
    margin-right: 15px !important;
  }

  .tabletViewGridTwo.ui.grid {
    .column {
      @media (max-width: 1199px) {
        width: 50% !important;
      }
    }
  }

  .threeColumn.ui.grid {
    >.column.wide.computer {
      @media only screen and (max-width: 1400px) and (min-width: 1199px) {
        width: 33.33% !important;
      }
    }
  }

  .common-shadow {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 5px;

    background-color: $white-color;
  }

  .blueDivider {
    border-top-color: $teacher-primary  !important;
  }

  .modal {
    left: initial !important;
    top: initial !important;
    bottom: initial !important;
    right: initial !important;
  }

  .commonTable {
    overflow: auto;
  }

  .field {
    label {
      font-weight: 100 !important;
    }

    .ui.input {
      input {
        font-family: $app-primary-regular-font;

        &:focus {
          border-color: $teacher-primary  !important;
        }
      }
    }

    textarea {
      &:focus {
        border-color: $teacher-primary  !important;
      }
    }
  }

  .teacher-primary {
    color: $teacher-primary;

    &:hover {
      color: $teacher-primary;
    }
  }

  .ui.checkbox {
    input {
      &:checked {
        ~label {
          &:before {
            background-color: $teacher-primary  !important;
            border-color: $teacher-primary  !important;
          }

          &:after {
            color: $white-color  !important;
            z-index: 1 !important;
          }
        }
      }

      &:focus {
        ~label {
          &:before {
            border-color: $teacher-secondary-color  !important;
          }
        }
      }
    }

    label {
      &:after {
        z-index: 1 !important;
      }
    }
  }

  .libraryNoRecord {
    width: 100%;
    padding: 25px 0 0;

    h3.ui.header {
      color: #000000;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 21px;
      margin: 0 0 16px;
    }
  }

  h5.ui.header.forCommonTable {
    text-align: center;
    padding: 50px 0 !important;
    width: 100%;
  }

  .flexible-modal {
    padding: 15px;
    overflow: auto;
  }

  .flexible-modal {
    position: absolute;
    z-index: 1;
    border: 1px solid #ccc;
    background: white;
  }

  .flexible-modal-mask {
    position: fixed;
    height: 100%;
    background: rgba(255, 255, 255, 0) !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .flexible-modal-resizer {
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: se-resize;
    margin: 5px;
    border-bottom: solid 2px #333;
    border-right: solid 2px #333;
  }

  .flexible-modal-drag-area {
    background: rgba(255, 255, 255, 0.2) !important;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: move;
  }

  .flexible-modal-drag-area-left,
  .flexible-modal-drag-area-bottom,
  .flexible-modal-drag-area-right {
    display: none;
  }

  .sideBorder {
    border: 1px solid $teacher-primary  !important;
  }

  //   cursor: pointer !important;
  // }

  .field {
    position: relative;

    label {
      color: rgba(0, 0, 0, 0.87) !important;
    }

    .srv-validation-message {
      position: absolute;
      top: 100%;
      font-size: 12px;
      color: #f00;
      line-height: 12px;
    }

    &.error {
      .ui.input {
        input {
          background: #fff6f6;
          border-color: #e0b4b4;
          color: #9f3a38;
        }
      }
    }
  }

  .loginPassword {
    position: relative;

    .passwordIcon {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
  }
}

//  common css to use

.textOverflowHidden {
  text-overflow: ellipsis;
  overflow: hidden;
}

.leftWrapper p a {
  color: $teacher-primary;
}

//*************RenderBookCss********************//

// Lesson Plan Page
.lessonPlanPage {
  padding: 4px 10px 0 0;
  position: relative;
  .bookDetailMenu {
    display: none;
    position: absolute;
    top: 50%;
    left: -20px;
    writing-mode: vertical-rl;
    transform: rotate(180deg) translateY(50%);
    background-color: $teacher-primary;
    color: $white-color;
    padding: 10px 0!important;
    font-size: 13px;
    font-variant: small-caps;
    cursor: pointer;
    z-index: 1;
  }
  .bookSideBar {
    display: none;
    position: absolute;
    left: 1px;
    top: 46px;
    box-shadow: 0 0 10px rgba(0, 0, 0,.3);
    z-index: 1001;
    background-color: #fff;
    bottom: 47px;
    padding: 15px;
    width: 200px;
    &.active {
      display: block;
    }
    .leftWrapper {
      margin-left: 0;
      height: calc(100% - 170px);
    }
  }
  .bookImg {
    display: flex;
    align-items: self-start;
    .bookInnerImg {
      // box-shadow: -5px 5px 10px 0px rgba(168, 168, 168, 0.1), -5px 5px 10px 0px rgba(168, 168, 168, 1),
      //   -5px 5px 10px 0px rgba(168, 168, 168, 1);
      box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.3);
      min-width: 80px;
      height: 140px;
      width: 80px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .favouriteImg {
      margin: 2px 0 0 10.5px;
      cursor: pointer;
    }

    a {
      position: relative;
      display: block;

      &:before {
        content: "";
        background-color: #9b9b9b;
        display: block;
        height: 100%;
        left: 5px;
        position: absolute;
        width: 1px;
        opacity: 0.6;
        z-index: 1;
      }
    }

    .favourte {
      color: $teacher-secondary-color;
      font-size: 18px;
      margin: 0px 0 0 10px;
      cursor: pointer;
    }
  }

  .leftWrapper {
    margin: 31px 0 0 -20px;
    overflow: auto;
    height: calc(100vh - 220px);
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
    }

    h3.ui.header {
      color: $teacher-primary;
      font-family: $app-medium-font;
      font-size: 17px;
      line-height: 23px;
      margin: 0 0 5px;
      cursor: pointer;

      &.myLessonPlan {
        margin: 25px 0 5px;
      }
    }

    p {
      color: $teacher-primary;
      font-size: 12px;
      font-family: $app-primary-medium-font;
      font-weight: 500;
      letter-spacing: 0.24px;
      line-height: 17px;
      cursor: pointer;

      &.upparcase {
        text-transform: uppercase;
      }

      &.viewAll {
        padding: 10px 0 0;
      }
    }

    .chapterColor {
      color: $teacher-primary;
    }

    h5.ui.header {
      color: $teacher-primary;
      font-family: $app-primary-bold-font;
      font-size: 12px;
      letter-spacing: 0.24px;
      line-height: 19px;
      text-transform: uppercase;
      margin: 10px 0 5px;
      cursor: pointer;

      &.goldColor {
        color: $teacher-secondary-dark-color;
      }
    }

    h4.ui.header {
      color: $teacher-secondary-dark-color;
      font-family: $app-medium-font;
      font-size: 17px;
      font-weight: 500;
      line-height: 22px;
      margin: 15px 0 10px -5px;
      cursor: pointer;

      i.icon {
        margin: -8px 5px 0 0;
        font-size: 18px;
      }
    }
  }

  .bookPage {
    padding-top: 4px !important;

    // &.leftBookWrapper {
    //   // padding: 0 25px 0 0;
    // }
    .shadow {
      position: absolute;
      inset: 0;
      background: rgba(255, 255, 255, 0.8);
      z-index: 1;
      display: none;
      cursor: pointer;
      &.active {
        display: block;
      }
    }
    &.rightBookWrapper {
      padding: 0 45px 0 0;
    }

    .innerPage {
      .bookHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 22px;

        //  margin: 0 43px 0 0;
        .left {
          display: flex;
          align-items: center;

          &.seachIconHide {
            // min-height: 40px;

            .ui.search {
              position: absolute;
              left: 20px;
              right: 0;
              z-index: 1020;

              .ui.input {
                width: 100%;
              }
            }
            .widthRatio {
              width: 22px;
            }
          }

          img.ui.image {
            margin: 0 10px 0 0;
            cursor: pointer;
          }

          i.icon {
            color: $teacher-primary;
            // margin: -5px 0 0;
            cursor: pointer;

            &.plus {
              margin: -5px 0 0;
            }
          }

          .font-size-changer {
            width: 35px !important;
            height: auto !important;

            .font-size-changer-buttons {
              width: auto !important;
              height: auto !important;

              .font-size-up,
              .font-size-down {
                font-size: 19px;
                color: #003e52;
                border: none;
                width: auto !important;
                height: auto !important;
                cursor: pointer;
                line-height: normal !important;
                font-family: $app-secondary-regular-font;
              }
            }
          }
        }

        .center {
          p {
            color: $teacher-primary;
            font-size: 11px;
            letter-spacing: 0.26px;
            line-height: 22px;
            text-align: center;
            text-transform: uppercase;

            @media (max-width: 1199px) {
              max-width: 90px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }

        .right {
          display: flex;
          align-items: center;

          img.ui.image {
            margin: 0 0 0 10px;
            cursor: pointer;

            &.menuIcon {
              width: 22px;
            }
          }
        }
      }

      .bookContentOuter {
        margin: 15px 0 0;
        // padding: 0 25px 0 0;
        position: relative;
       
        .editDropDown {
          position: absolute;
          right: 0;
          top: 0;

          .menu {
            width: 130px;
            border: 1px solid rgba(1, 62, 82, 0.38);
            border-radius: 5px;
            background-color: #fffcf6;
            box-shadow: 0 23px 45px 11px rgba(0, 0, 0, 0.1);

            .item {
              border-top: 1px solid rgba(1, 62, 82, 0.38);
              text-transform: uppercase;
              padding: 5px 10px !important;
              color: $teacher-primary  !important;

              &:first-child {
                border: none;
              }

              span {
                margin: 0;
                color: $teacher-primary  !important;
                font-size: 10px !important;
                font-family: $app-primary-medium-font;
                letter-spacing: 0.24px;
                line-height: 26px;
              }
            }
          }
        }

        .blockLines {
          position: absolute;
          right: -30px;
          top: 0;
          bottom: 0;
          display: flex;
          flex-direction: column;
          cursor: pointer;

          .innerLine {
            width: 2px;
            height: 11px;
            background-color: $teacher-primary;
            flex: auto;
            margin: 0 0 10px;
            position: relative;

            &:last-child {
              margin: 0;
            }

            &.active {
              background-color: $teacher-secondary-color;
            }

            .ui.dropdown {
              position: absolute;
              inset: 0;
            }
          }
        }
      }

      .bookContent {
        .bookContentInner {
          border: 0.5px solid rgba(0, 62, 82, 0.33);
          background-color: #ffffff;
          height: calc(100vh - 122px);
          padding: 0 15px 12px 15px;
          position: relative;
          -ms-overflow-style: none;
          scrollbar-width: none;

          &.engegementWrapper {
            margin: 37px 0 0;
            // margin: 55px 0 0;
            padding: 0;
          }

          &.active {
            .customScroll {
              >div {
                &:last-child {
                  background-color: #bf964a !important;

                  div {
                    background-color: #f3d4a5 !important;

                    &::before {
                      content: "";
                      background-color: #f3d4a5 !important;
                    }
                  }
                }
              }
            }
          }

          .customScroll {
            width: calc(100% + 35px) !important;
            // height: 101.8% !important;
            height: calc(100% + 10px) !important;

            >div {
              margin: 0 !important;
              -ms-overflow-style: none;
              /* IE and Edge */
              scrollbar-width: none;

              /* Firefox */
              &::-webkit-scrollbar {
                display: none;
              }

              &:nth-child(2) {
                div {
                  display: none !important;
                }
              }

              &:last-child {
                width: 2px !important;
                right: 4px !important;
                bottom: 0 !important;
                top: 0 !important;
                border-radius: 3px !important;
                background-color: rgba(0, 62, 82, 0.31) !important;

                div {
                  position: initial !important;
                  background-color: rgb(0, 62, 82) !important;
                  width: 6px !important;
                  margin: 0 0 0 -2px !important;

                  &::before {
                    content: "";
                    background-color: rgb(0, 62, 82) !important;
                    position: absolute;
                    bottom: 100%;
                    width: 2px;
                    left: 2px;
                    right: 0;
                    height: 100%;
                  }
                }
              }
            }
          }

          .ui.dropdown.bookDetailPopup {
            &.topleft {
              // position: absolute;
              // top: 10px;
              // left: 35px;
              // z-index: 999;

              i.icon {
                color: $teacher-secondary-color;
                font-size: 10px;
              }
            }

            .text {
              background-color: rgba(217, 174, 138, 0.25);
              cursor: pointer;
              margin: 0 5px 0 0;
              padding: 0 3px;
            }

            .menu {
              white-space: initial;
              width: 462px;
              border: 0.5px solid rgba(1, 62, 82, 0.38);
              background-color: #fffcf6;
              box-shadow: 0 10px 37px 1px rgba(0, 0, 0, 0.31);
              min-width: auto;
              padding: 15px;

              @media (max-width: 1300px) {
                width: 300px;
              }

              .tags {
                color: $teacher-primary;
                font-size: 11px;
                font-family: $app-primary-bold-font;
                letter-spacing: 0.26px;
                line-height: 25px;
                white-space: initial;
              }

              p {
                color: $black-color;
                font-size: 12px;
                line-height: 24px;
                font-family: $app-primary-regular-font;
                padding: 0;
                white-space: initial;
                width: 100% !important;
              }

              a {
                color: $teacher-secondary-dark-color;
                font-size: 11px;
                font-family: $app-primary-bold-font;
                letter-spacing: 0.26px;
                line-height: 25px;
                display: inline-block;
                margin: 5px 10px 0 0;
                white-space: initial;
              }
            }
          }

          p {
            font-family: $app-secondary-regular-font;
            color: $black-color;
            // font-size: 12px !important;
            font-size: 14px;
            letter-spacing: 0.38px;
            line-height: 24px;
            padding: 0 0 10px;
            width: 100% !important;

            &.isContentNotAvailable {
              .stl_02 {
                .stl_03 {
                  padding-top: 15px;

                  img {
                    position: initial;
                    width: calc(100% - 35px);
                  }
                }
              }
            }

            &.pdfBookView {
              font-size: 16.5px;

              @media (max-width: 1930px) {
                font-size: 15.5px;
              }

              @media (max-width: 1830px) {
                font-size: 14.5px;
              }

              @media (max-width: 1730px) {
                font-size: 13.5px;
              }

              @media (max-width: 1650px) {
                font-size: 12.5px;
              }

              @media (max-width: 1530px) {
                font-size: 11.5px;
              }

              @media (max-width: 1430px) {
                font-size: 10.5px;
              }
            }

            &.epubBookView {
              font-size: 20.5px;

              @media (max-width: 1890px) {
                font-size: 19.5px;
              }

              @media (max-width: 1820px) {
                font-size: 18.5px;
              }

              @media (max-width: 1740px) {
                font-size: 17.5px;
              }

              @media (max-width: 1660px) {
                font-size: 16.5px;
              }

              @media (max-width: 1590px) {
                font-size: 15.5px;
              }

              @media (max-width: 1530px) {
                font-size: 15px;
              }

              @media (max-width: 1460px) {
                font-size: 13.5px;
              }

              @media (max-width: 1370px) {
                font-size: 12.5px;
              }

              @media (max-width: 1160px) {
                font-size: 11.5px;
              }
            }

            .page {
              width: 100% !important;
              height: auto !important;

              span {
                font-size: 14px !important;
              }

              .image {
                img {
                  width: 100%;
                }
              }
            }
          }

          // .stl_01 {
          //   z-index: initial !important;
          // }
        }
      }

      .pagination {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0 0;

        // margin: 0 44.5px 0 0;
        // border-top: 0.5px solid rgba(0, 62, 82, 0.33);
        &.active {
          position: relative;
          z-index: 1050;

          .upper-case {
            color: #fff;
          }

          .jumpTo {
            span {
              color: #fff;
            }
          }
        }

        i.icon {
          font-size: 15px;
          color: $teacher-secondary-color;
          text-align: left;
          margin: -3px 0 0;
          cursor: pointer;

          &:last-child {
            text-align: right;
          }
        }

        .jumpTo {
          display: flex;
          align-items: center;

          p {
            color: #003e52;
            font-size: 11px;
            letter-spacing: 0.26px;
            line-height: 22px;
            text-transform: uppercase;
          }

          span {
            color: $teacher-primary;
            font-size: 11px;
            letter-spacing: 0.26px;
            line-height: 22px;
            padding-left: 15px;
            text-transform: uppercase;
          }

          .ui.input {
            margin: 0 5px 0 5px;
            display: block;

            input {
              width: 37px;
              border-radius: 0;
              text-align: center;
              padding: 0;
              border: none;
              border-bottom: 1px solid $teacher-primary;
              color: #bf964a;
              height: auto;
            }
          }

          i.icon {
            color: #bf964a;
            font-size: 16px;
            letter-spacing: 0.4px;
            line-height: 18px;
          }
        }
      }
    }

    &.lessonPlanDetail {
      .innerPage {

        // .bookHeader {
        //   padding: 0 36px 0 0;
        // }
        .bookContent {
          .bookContentInner {
            padding: 37px 15px 5px 15px;

            &.createLessonPlan {
              display: flex;
              align-items: center;
              justify-content: center;

              .innerCreatePlan {
                text-align: center;

                h2.ui.header {
                  color: $teacher-primary;
                  font-family: $app-medium-font;
                  font-size: 23px;
                  line-height: 29px;
                  text-align: center;
                }

                p {
                  color: $teacher-primary;
                  font-size: 14px;
                  letter-spacing: 0.28px;
                  line-height: 17px;
                  text-align: center;
                  font-family: $app-primary-regular-font;
                }
              }
            }

            &.lessonStack {
              padding-top: 10px;

              h4.ui.header {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                color: $teacher-primary;
                font-family: $app-primary-bold-font;
                font-size: 14px;
                letter-spacing: 0.33px;
                line-height: 25px;

                img.ui.image {
                  max-width: 18px;
                  margin: -1px 10px 0 0;
                }
              }

              .ui.list {
                height: calc(100% - 75px);
                overflow: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                  display: none;
                }

                .item {
                  padding: 10px 5px;

                  .content {
                    .editDropDown {
                      position: relative;

                      i.icon {
                        color: $teacher-secondary-dark-color;
                        font-size: 16px;

                        &:hover {
                          color: $teacher-primary;
                        }
                      }
                    }
                  }

                  .lessonStacktitle {
                    color: $teacher-primary;
                    font-size: 14px;
                    letter-spacing: 0.33px;
                    line-height: 19px;
                  }

                  .addnew {
                    color: $teacher-secondary-dark-color;
                    font-family: $app-primary-medium-font;
                    font-size: 13px;
                    letter-spacing: 0.31px;
                    line-height: 19px;
                  }
                }
              }

              .viewBlocks {
                color: $teacher-secondary-dark-color;
                font-family: $app-primary-medium-font;
                font-size: 13px;
                letter-spacing: 0.31px;
                line-height: 19px;
                text-align: center;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 10px;
                // cursor: pointer;
              }
            }

            .title {
              color: $teacher-primary;
              font-size: 12px;
              letter-spacing: 0.2px;
              line-height: 10.8px;
              text-align: center;
              position: relative;
              text-transform: uppercase;

              i.icon {
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;
              }
            }

            h3.ui.header {
              color: $teacher-primary;
              font-family: $app-medium-font;
              font-size: 18px;
              line-height: 22px;
              text-align: center;
              position: relative;
              margin: 5px 0 0;

              &::after {
                content: "";
                position: absolute;
                right: 0;
                width: 35%;
                height: 0.9px;
                background-color: $teacher-primary;
                top: 50%;
                transform: translateY(-50%);
                opacity: 0.19;
              }

              &::before {
                content: "";
                position: absolute;
                left: 0;
                width: 35%;
                height: 0.9px;
                background-color: $teacher-primary;
                top: 50%;
                transform: translateY(-50%);
                opacity: 0.19;
              }
            }

            h5.ui.header {
              color: $teacher-primary;
              font-size: 15px;
              letter-spacing: 0.31px;
              line-height: 23.6px;
              position: relative;
              margin: 25px 0 5px;
              font-family: $app-primary-semibold-font;

              i.icon {
                position: absolute;
                top: 0;
                right: 0;
                font-size: 13px;
                font-weight: 100;
                cursor: pointer;
                margin: 0;
              }
            }

            .CustomText {
              color: $black-color;
              font-size: 13px;
              letter-spacing: 0.31px;
              line-height: 23.6px;
              margin: 0 0 15px;
              display: block;
            }

            .ui.list {
              li {
                color: $black-color;
                font-size: 12px;
                letter-spacing: 0.28px;
                line-height: 23.6px;
              }
            }

            div {
              .CustomLink {
                color: $teacher-secondary-dark-color;
                font-size: 12px;
                letter-spacing: 0.24px;
                line-height: 17px;
                display: inline-block;
                margin: 10px 10px 0 0;
                font-family: $app-primary-bold-font;
              }
            }
          }
        }
      }

      .pagination {
        justify-content: center;
      }
    }
  }

  .chooseLessonPlann {
    padding: 57px 0 0;
    height: 290px;

    h2.ui.header {
      color: $teacher-primary;
      font-family: $app-medium-font;
      font-size: 23px;
      line-height: 29px;
    }

    h5.ui.header {
      color: $teacher-primary;
      font-size: 12px;
      letter-spacing: 0.28px;
      line-height: 17px;
      text-transform: uppercase;
      margin: 15px 0 3px;
      font-family: $app-primary-semibold-font;
    }

    p {
      color: $teacher-primary;
      font-size: 12px;
      letter-spacing: 0.28px;
      line-height: 17px;
    }
  }

  .viewAllLessons {
    .ui.list.commonList {
      margin: 0;
      overflow: auto;
      height: calc(100vh - 370px);
    }
  }

  .fullEngagement {
    position: fixed;
    left: 150px;
    top: 0;
    bottom: 0;
    border: 1px solid rgba(1, 62, 82, 0.21);
    background-color: #fffcf6;
    box-shadow: -10px 23px 45px 11px rgba(0, 0, 0, 0.13);
    z-index: 1040;
    width: 726px;

    .fullEngagementInner {
      height: 100%;
      padding: 45px 27px 30px 45px;
      position: relative;

      .closeFullEngagement {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }

      .fullEngagementContent {
        overflow-y: auto;
        height: calc(100vh - 130px);
        padding: 0 20px 0 0px;

        &::-webkit-scrollbar-track {
          background: linear-gradient(to right,
              transparent 0%,
              transparent 35%,
              $teacher-primary 50%,
              transparent 61%,
              transparent 100%);
          border-radius: 5px;
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $teacher-primary;
          border-radius: 5px;
        }
      }

      h2.ui.header {
        color: $teacher-primary;
        font-size: 16px;
        letter-spacing: 0.38px;
        line-height: 27px;
        font-family: $app-primary-extrabold-font;
        margin: 0;
      }

      span {
        color: $teacher-primary;
        font-size: 10px;
        font-family: $app-primary-medium-font;
        letter-spacing: 0.24px;
        line-height: 26px;
      }

      pre {
        white-space: break-spaces;
        color: $black-color;
        font-size: 14px;
        letter-spacing: 0.33px;
        line-height: 26px;
        padding: 15px 0;
        font-family: $app-primary-regular-font;
      }

      p {
        color: $black-color;
        font-size: 14px;
        letter-spacing: 0.33px;
        line-height: 26px;
        padding: 15px 0;

        span {
          color: $teacher-primary;
          font-family: $app-primary-bold-font;
          font-size: 14px;
        }
      }

      h3.ui.header {
        color: $teacher-primary;
        font-family: $app-primary-bold-font;
        font-size: 14px;
        letter-spacing: 0.33px;
        line-height: 27px;
      }

      .aleeCoachWrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .aleeCoach {
          background-color: $white-color;
          width: 48%;
          margin-bottom: 24px;
        }
      }
    }
  }
}

.Admin {
  .bookContentInner {
    height: calc(100vh - 200px) !important;
  }
}

.rdw-editor-main.addNotes {
  // height: 150px !important;
  height: calc(100vh - 450px) !important;
}

.rdw-editor-toolbar.toolbarClassName.edit-notes {
  visibility: hidden !important;
}

.rdw-editor-toolbar.toolbarClassName.creating-editor {
  visibility: visible !important;
}

.editorEditClassName {
  .rdw-editor-main {
    overflow: hidden;
  }
}

.saveBlock-btn {
  margin: 20px !important;
}

.save-block {
  text-align: center !important;
}

.statsToggle {
  color: $teacher-primary;
}

// .rdw-dropdown-carettoopen {
//   right:  -57%;
// }

.add-Student {
  color: #c19749;
  font-family: inter-medium;
  font-size: 13px;
  letter-spacing: 0.31px;
  line-height: 19px;
  cursor: pointer;
}

.indiviCompany {
  display: flex;

  .ui.checkbox {
    margin: 0 10px;
  }
}

.pointer {
  cursor: pointer !important;
}

.field.block-name {
  margin-top: 10px;
}

.umbrella-standard {
  margin: 15px 0 0 26px;
}

.grade-standard-checkbox {
  position: relative;

  .ui.checkbox {
    label {
      max-width: 120px;
      word-break: break-all;
    }
  }
}

.standard-close-icon {
  position: absolute;
  top: 0;
  left: 120px;
  padding-left: 12px;
  cursor: pointer !important;
}

.umbrela-dropdown {
  margin-left: 39px;
}

.ui.dropdown .menu>.item {
  // padding: 3px 30px 3px 4px !important;
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  height: auto;
  text-align: left;
  border-top: none;
  line-height: 1em;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.78571429rem 1.14285714rem !important;
  font-size: 1rem;
  text-transform: none;
  font-weight: 400;
  box-shadow: none;
}

.bookAuthor {
  display: flex;
  margin: 0 0 20px;
  border-top: 1px solid #e3e3e3;
  padding: 20px 0 0;
  align-items: self-start;

  &:last-child {
    margin: 0;
  }

  &:first-child {
    border: none;
    padding: 0;
  }

  .bookAuthorForm {
    display: flex;
    width: 100%;
    padding: 0 0 0 20px;
    align-items: center;

    .bookAuthorFormInner {
      width: 100%;
      padding: 0 20px 0 0;
    }
  }

  .uploadedImg {
    img {
      margin: 0 !important;
    }
  }

  .ui.input {
    margin: 0 0 30px;
  }

  .authorAddrow {
    text-align: center;
  }
}

.chapterToggle {
  position: absolute;
  right: 5px;
  top: 0px;
}

.actionBtn {
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  padding: 32px 0 0;
  grid-gap: 24px;

  a {
    color: $teacher-secondary-dark-color;
    font-size: 13px;
    font-family: $app-primary-semibold-font;
    letter-spacing: 0.31px;
    line-height: 19px;
  }

  .saveProfiled {
    color: $teacher-secondary-dark-color  !important;
    font-size: 13px !important;
    font-family: $app-primary-semibold-font  !important;
    letter-spacing: 0.31px !important;
    line-height: 19px !important;
    border: 1.5px solid #bf964a !important;
    background-color: transparent !important;

    &:hover {
      background-color: $teacher-secondary-dark-color  !important;
      color: #fff !important;
    }

    &:active,
    &:focus {
      background-color: $teacher-secondary-dark-color  !important;
      color: #fff !important;
    }
  }
}

.padding-on-umbrela-standrd {
  padding: 1.5px;
}

// .Admin {
//   .stl_02 {
//     left: -35px;
//   }
// }

.stl_02 {
  // font-size: 114px !important;
  // height: 60em !important;
  height: 100% !important;
  position: relative;
}

.stl_11 {
  // font-size: 1em !important;
  font-family: times_new_roman !important;
  color: #000000;
}

.stl_04 {
  object-fit: cover;
  // position: initial !important;
}

.stl_07 {
  transform: none !important;
  font-family: times_new_roman !important;
  z-index: initial !important;
}

.stl_08 {
  // font-size: 1em!important;
  font-family: times_new_roman !important;
}

// .dropbtn{
//   font-size: 30px !important;
//   color: #f00;
//   background-color: yellow !important;
// }

.dropbtn:hover .dropdown-content {
  display: block;
}

// .dropdown:hover .dropdown-content {
//   display: block;
// }

.dropdown {
  position: relative;
}

// .dropdown {
//   white-space: initial;
//   width: 350px;
//   border: 0.5px solid rgba(1, 62, 82, 0.38);
//   background-color: #fffcf6;
//   box-shadow: 0 10px 47px 1px rgb(0 0 0 / 31%);
//   min-width: auto;
//   padding: 15px;
// }

.dropdown-content {
  // width: 3400px;
  min-width: auto;
  display: none;
  position: absolute;
  z-index: 1050;
  top: -260px;
  // padding: 203px 50px 0;
  padding: 265px;
  margin: 168px 50px 0;
  // height: 3400px;
  // overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  // width: 4620px;
  // @media (max-width:1460px) {
  //   width: 3620px;
  // }

  &::-webkit-scrollbar {
    display: none;
  }

  .engagement-text {
    color: $black-color;
    font-size: 12px;
    line-height: 24px;
    font-family: $app-primary-regular-font;
    padding: 0;
    white-space: initial;
    width: 199% !important;
  }
}

.dropdown-main-content {
  white-space: initial;
  border: 5px solid rgba(1, 62, 82, 1);
  background-color: #fffcf6;
  box-shadow: 0 50px 232px 1px rgb(0 0 0 / 31%);
  min-width: auto;
  padding: 150px;
  position: relative;
  z-index: 1050;
  margin: 0 0 250px;
  border-radius: 2.985714rem;
  display: block;
  // width: 3500px;
  width: 4620px;

  .engagement-text {
    color: $black-color;
    font-size: 12px;
    line-height: 24px;
    font-family: $app-primary-regular-font;
    padding: 0;
    white-space: initial;
    width: 199% !important;
  }
}

.content-rendering {
  height: 30px;
  width: 300px;
  border: solid 1px grey;
  background-color: #fffcf6;
  display: none;
  position: absolute;
}

.fullmoveToEngagement {
  color: #c19749;
  cursor: pointer !important;
  font-size: 11px;
  font-family: inter-bold;
  letter-spacing: 0.26px;
  line-height: 25px;
  display: inline-block;
  margin: 5px 25px 0 0;
  white-space: initial;

  &:last-child {
    margin-right: 0;
  }
}

.engagement-tag {
  color: #013e52;
  font-size: 11px;
  font-family: inter-bold;
  letter-spacing: 0.26px;
  line-height: 25px;
  white-space: initial !important;
}

// .dropdown-content {
// .engagement-tag {
//   color: #000 !important;
//   letter-spacing: 0.38px !important;
//   padding: 0 0 10px !important;
//   width: 100% !important;
// }

// }

.alternateBtn.ui.button {
  background-color: $teacher-secondary-dark-color;
  color: $white-color;
  font-size: 15px;
  border-radius: 0;
  padding: 10px 20px;
  border: 1px solid $teacher-secondary-dark-color  !important;

  &:hover {
    background-color: transparent !important;
    color: $teacher-secondary-dark-color  !important;
  }

  &:active,
  &:focus {
    background-color: $white-color;
    color: $teacher-primary;
  }
}

.aleeCoach {
  border: 1px solid rgba(1, 62, 82, 0.24);
  border-radius: 27px;
  background-color: rgba(255, 243, 217, 0.24);
  padding: 37px 29px 45px;
  display: block;
  cursor: pointer;
  min-height: 325px;
  height: 100%;
  position: relative;

  h4.ui.header {
    color: $teacher-primary;
    font-family: $app-medium-font;
    font-size: 16px;
    line-height: 21px;
    margin: 0;
  }

  h5.ui.header {
    color: $teacher-primary;
    font-family: $app-primary-bold-font;
    font-size: 10px;
    letter-spacing: 0.24px;
    line-height: 25px;
    margin: 0 0 5px;
    text-transform: uppercase;
  }

  p {
    color: $black-color;
    font-size: 12px;
    letter-spacing: 0.28px;
    line-height: 22px;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .videoPlay {
    position: absolute;
    bottom: 15px;

    i.icon {
      color: $teacher-primary;
    }

    span {
      color: $teacher-primary;
      font-family: $app-primary-bold-font;
      font-size: 10px;
      letter-spacing: 0.24px;
      line-height: 25px;
      text-transform: uppercase;
    }
  }
}

.marginTop {
  margin-top: 22px !important;
}

// .moveToEngagement {
//   color: #c19749;
//   cursor: pointer;
//   font-size: 1em;
//   font-family: inter-bold;
//   letter-spacing: 0.26px;
//   line-height: 25px;
//   display: inline-block;
//   margin: 5px 10px 0 0;
//   white-space: initial;
// }

.engagement-detail {
  color: #013e52;
  font-family: inter-bold;
  font-size: 110px;
  line-height: normal;
  // font-weight: bold;
  // margin-bottom: 45px;
}

.readToEngagement,
.addToEngagement {
  color: #c19749;
  cursor: pointer;
  font-family: inter-bold;
  font-size: 110px;
  letter-spacing: 0px;
  word-spacing: 0.26px;
  line-height: normal;
}

.readToEngagement {
  margin: 0 250px 0 0;
}

.engagement-description {
  font-family: $app-primary-regular-font  !important;
  font-size: 122px;
  margin: 100px 0px;
  line-height: 240px;
}

.engagementButtons {
  display: flex;
}

.editDropDown {
  font-style: normal !important;
  line-height: normal;
}

.engagementIcon {
  position: absolute;
  right: 67px;
  top: 56px;
}

.editIconDropDown {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0px !important;
  font-size: 13px !important;
  cursor: pointer;
}

.aleeCoachEditor {
  border: 1px solid #e3e3e3;
  padding: 5px;
  margin: 10px 0px 0;
  height: 250px !important;
  .DraftEditor-editorContainer,.DraftEditor-root {
    height: auto;
  }
  // &::-webkit-scrollbar {
  //   width: 0px;
  // }
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 5px rgba(1, 62, 82, 0.38);
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #bf964a;
    width: 6px;

    &:hover {
      background-color: #bf964a;
    }
    
  }

  .rdw-link-decorator-wrapper {
    a {
      color: $teacher-primary;

      span {
        font-family: $app-primary-semibold-font;
        color: $teacher-primary;
      }
    }
  }
}

iframe {
  margin: 20px;
}

.bookMarkIcon {
  font-size: 17px !important;
  margin: 0 10px 0 0 !important;
}

.student-btns {
  margin: 13px 0 0;

  .ui.button {
    margin: 0 17px 17px 0 !important;
    min-width: 120px;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.newTag {
  position: absolute;
  right: 110px;
  top: 214px;
}

.stl_02 {
  width: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.viewEngagement,
.editEngagement,
.duplicateEngagement,
.deleteEngagement,
.moreEngagement {
  color: #c19749;
  cursor: pointer !important;
  font-size: 110px;
  font-family: $app-primary-bold-font;
  margin: 0 250px 0 0;
  line-height: normal;
}

// .deleteEngagement {
//   margin: 0;
// }

.inline {
  display: inline !important;
}

.no-chapter {
  text-align: center;

  p {
    color: $teacher-primary  !important;
  }
}

.JumpClass {
  position: relative;
  right: 104px;
  top: 5px;
}

.item.jumpPointer {
  top: 8px;
  position: absolute;
  right: -67px;
  cursor: pointer;
}

.edit-lesson-only {
  padding-top: 20px !important;
  background-color: transparent !important;
  border: 0 !important;
}

.edit-lesson-icon {
  display: flex;
  align-items: center;
  padding: 20px 0 0;

  img {
    margin: 0 17px 0 0 !important;
  }
}

.edit-lesson-name {
  font-size: 23px !important;
  line-height: 29px !important;
  color: #013e52 !important;
  font-family: PPHatton-Medium !important;
  margin: 0 0 6px !important;
}

.edit-lesson-author-detail {
  font-size: 12px !important;
  line-height: 17px !important;
  letter-spacing: 0.28px !important;
  color: #013e52 !important;
}

.jumpWrapper {
  display: flex;
  align-items: center;
  white-space: nowrap;
  grid-gap: 10px;
  margin-right: 15px;

  input {
    max-width: 80px !important;
  }
}

.removeBtn {
  position: relative !important;

  i.close.icon {
    position: absolute !important;
    font-size: 13px;
    right: -1px;
    top: -1px;
    width: 6px;
    cursor: pointer;
  }
}

.column.sub-password {
  position: relative;
}

i.eye.icon.subAdmin-password {
  position: absolute;
  right: 19px;
  top: 45px;
  cursor: pointer;
}

.upper-case {
  text-transform: uppercase;
}

.headingWithFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &.mainPageDropDown {
    .aleeLibrary {
      .filters {
        .ui.dropdown {
          .menu {
            max-height: calc(100vh - 630px);
            min-height: 72px;
          }

          &.standard {
            .menu {
              .ui.list {
                max-height: calc(100vh - 630px);
                min-height: 72px;
                overflow: auto;
              }
            }
          }
        }
      }
    }
  }

  &.topDropdown {
    .aleeLibrary {
      .filters {
        .ui.dropdown {
          .menu {
            max-height: calc(100vh - 200px);
          }

          &.standard {
            .menu {
              .ui.list {
                max-height: calc(100vh - 200px);
                overflow: auto;
              }
            }
          }
        }
      }
    }
  }

  .liberayBooksInner {
    margin: 65px 0 0;
    width: 100%;
  }
}

.aleeCoachPage {
  // background-color: #fbfbfb;
  padding: 9px 0 0px;

  .aleeLibrary {
    padding: 0 !important;

    .liberaryHeading {
      font-size: 34px;
      line-height: 42px;

      &.titleHead {
        margin: 35px 0 14px !important;
      }
    }
  }

  .searchInput {
    .ui.input {
      width: 100%;

      input {
        border-color: #c19749;
        background-color: transparent;
        height: 44px;
        width: 100%;

        &::placeholder {
          color: #c19749;
          font-family: $app-primary-regular-font;
          font-size: 12px;
        }
      }

      i.icon {
        color: #c19749;
        opacity: 1;
      }
    }
  }

  .bookDetail {
    padding-top: 0;

    .detailTabs {
      .ui.secondary.menu {
        margin: 21px 0 0 !important;

        >.item {
          margin: 0 29px 0 0 !important;

          &:last-child {
            margin: 0 !important;
          }

          @media (max-width: 1350px) {
            margin: 0 10px 0 0 !important;
          }
        }
      }

      .ui.tab {
        margin-top: 33px !important;
      }
    }
  }

  .backtoAlee {
    border-bottom: 1px solid rgba(0, 0, 0, 0.18);
    padding: 0 0 10px;

    a {
      color: $teacher-primary;
      font-size: 13px;
      letter-spacing: 0.31px;
      line-height: 15px;
    }
  }

  .aleeCoachSummary {
    p {
      color: $black-color;
      font-size: 13px;
      letter-spacing: 0.31px;
      line-height: 27px;

      span {
        color: $teacher-primary;
        font-family: $app-primary-bold-font;
      }
    }

    h3.ui.header {
      color: $teacher-primary;
      font-family: $app-medium-font;
      font-size: 23px;
      line-height: 29px;
      margin: 18px 0 9px;
    }

    iframe {
      width: 100%;
      margin: 32px 0;
    }
  }

  .summaryText {
    &.left {
      padding-right: 13px;

      a {
        span {
          color: #4183c4 !important;
        }
      }
    }

    &.right {
      padding-left: 13px;

      a {
        span {
          color: #4183c4 !important;
        }
      }
    }

    p {
      color: #000000;
      font-family: $app-primary-regular-font;
      letter-spacing: 0.31px;
      line-height: 27px;

      span {
        color: initial !important;
        background-color: transparent !important;
        font-size: initial !important;
        font-family: $app-primary-regular-font  !important;
      }
    }

    iframe {
      width: 100%;
      margin: 20px 0 !important;
      min-height: 300px;
    }
  }
}

.blue-color {
  color: $teacher-primary;
}

.iconSearch {
  position: absolute;
  top: 17px;
  right: 6px;
  background: transparent !important;

  i {
    color: #d9ae8a !important;
    font-size: 15px;
  }

  &:before {
    content: "";
    background: #ddd;
    width: 1px;
    height: 65%;
    position: absolute;
    left: 2px;
    top: 7px;
  }
}

.change-password-form {
  width: 100%;
}

.text-right {
  text-align: right;
}

// .receipt {
//   display: none;
//   padding-right: 10px;
// }

// .display-none{
//   display: none;
// }

.color-blue {
  color: $teacher-primary;
}

.bookSummary {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1000;

  .bookSummaryContent {
    width: 726px;
    border: 1px solid rgba(1, 62, 82, 0.21);
    background-color: #fffcf6;
    box-shadow: -10px 23px 45px 11px rgba(0, 0, 0, 0.13);
    margin-left: auto;
    height: 100%;
    padding: 45px 27px 30px 45px;
    position: relative;

    .closeSummary {
      position: absolute;
      right: 18px;
      top: 15px;
      width: 26px;
      cursor: pointer;
    }

    h3.ui.header {
      color: $teacher-primary;
      font-family: $app-primary-extrabold-font;
      font-size: 16px;
      letter-spacing: 0.38px;
      line-height: 27px;
      margin: 0 0 8px;
    }

    .summaryDetails {
      a {
        span {
          color: #4183c4 !important;
        }
      }

      overflow: auto;
      height: calc(100% - 38px);
      padding: 0 30px 0 0;

      &::-webkit-scrollbar-track {
        background: linear-gradient(to right,
            transparent 0%,
            transparent 35%,
            $teacher-primary 50%,
            transparent 61%,
            transparent 100%);
        border-radius: 5px;
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $teacher-primary;
        border-radius: 5px;
      }

      * {
        background-color: transparent !important;
        font-family: $app-primary-regular-font  !important;
      }

      p {
        color: $black-color;
        font-size: 14px;
        letter-spacing: 0.33px;
        line-height: 26px;
        padding: 0 0 15px;

        span {
          background-color: transparent !important;
        }
      }

      h4.ui.header {
        font-size: 16px;
        margin: 0 0 5px;
        color: $teacher-primary;
        font-family: $app-primary-semibold-font;
      }
    }
  }
}

.srv-validation-message {
  color: #9f3a38 !important;
}

.iconSearched {
  position: absolute;
  top: 31px;
  right: 347px;
  background: transparent !important;

  i {
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
  }

  &:before {
    content: "";
    background: #ddd;
    width: 1px;
    height: 65%;
    position: absolute;
    left: 2px;
    top: 7px;
  }
}

// .seachIconHide {
//   .search.icon {
//     display: none !important;
//   }
// }

.text-left {
  text-align: left !important;
}

.ui.dropdown.error>.default.text,
.ui.dropdown.error>.text {
  color: rgba(191, 191, 191, 0.87);
}

.no-FeatureReading {
  text-align: center;
  position: absolute;
  top: 87px;
  left: 715px;
}

.full-eng-btn {
  margin-top: 10px;

  .nextBtn {
    float: right !important;
  }

  // display: flex;
  // align-items: center;
  // justify-content: space-between;
}

.ui.category.fluid.search.searchInput.show {
  .results {
    max-height: calc(90vh - 170px);
    overflow: auto;
    height: auto;
  }
}

.prefixSummary {
  padding: 68px 102px 89px 98px;
  margin: 15px;
}

.disabledType {
  opacity: 0.45 !important;
}

.rdw-link-modal {
  height: auto !important;

  .rdw-link-modal-input {
    margin: 0 0 15px !important;
    height: 30px;
  }

  .rdw-link-modal-target-option {
    display: flex;
    align-items: center;
  }
}

.rdw-embedded-modal {
  height: auto !important;

  .rdw-embedded-modal-header-option {
    width: 100%;
    margin: 0 0 15px;
  }

  .rdw-embedded-modal-link-input-wrapper {
    display: block;
  }

  .rdw-embedded-modal-size {
    gap: 10px;

    .rdw-embedded-modal-size-input {
      height: 30px;
    }
  }
}

.error {
  .dropdown {
    .text {
      color: #e0b4b4 !important;
    }
  }
}

.uppercase {
  text-transform: uppercase !important;
}

.myNotes {

  // text-align: center;
  .editor {
    border: 1px solid #eee;
    height: calc(100vh - 317px);
  }

  .ui.button {
    margin: 15px 0;
  }

  .myNotesInner {
    position: relative;

    span {
      color: #003e52;
      font-size: 10px;
      letter-spacing: 0.2px;
      line-height: 10.8px;
      text-align: center;
      text-transform: uppercase;
      display: block;
      margin: 0 0 10px;
    }

    .title {
      position: absolute;
      right: 0;
      background-color: #fff;
      padding: 0 10px 0 10px;
      z-index: 2;
      cursor: pointer;
    }

    .blockName {
      text-align: center;

      .srv-validation-message {
        margin-top: 5px;
        font-size: 12px !important;
        left: 0;
        right: 0;
        text-align: center;
      }

      h3.ui.header {
        &::before {
          content: "";
          position: absolute;
          left: 0;
          width: 35%;
          height: 0.9px;
          background-color: #013e52;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0.19;
        }

        &::after {
          content: "";
          position: absolute;
          right: 0;
          width: 35%;
          height: 0.9px;
          background-color: #013e52;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0.19;
        }
      }

      .field {
        text-align: center;
        margin: 0;
        z-index: 1;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          width: 35%;
          height: 0.9px;
          background-color: #013e52;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0.19;
        }

        &::after {
          content: "";
          position: absolute;
          right: 0;
          width: 35%;
          height: 0.9px;
          background-color: #013e52;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0.19;
        }

        .ui.input {
          width: 180px;
          z-index: 2;

          input {
            border: none;
            color: #003e52;
            font-family: $app-medium-font;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            position: relative;
            padding: 0 10px;
          }
        }
      }
    }
  }
}

.bookHeader {
  position: relative;
}

.bookStatusMsg {
  position: absolute;
  top: 6px;
  right: 150px;
}

.aleeCoachTabWithBtn {
  position: relative;

  .applyFilter {
    position: absolute;
    right: 11px;
    top: 9px;
    color: #013e52;
    font-size: 13px;
    cursor: pointer;
    font-family: $app-primary-semibold-font;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1260px) {
      top: 46px;
    }
  }
}

.mySchool {
  .schoolInfo {
    max-height: calc(100vh - 180px);
    overflow: auto;

    .school.special-label {
      cursor: default;
      pointer-events: none;
      text-decoration: none;
      color: white;
    }

    .schoolInfoInner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 12px 0;
      padding: 0 15px 0 0;

      .schoolInfoList {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(1, 62, 82, 0.32);
        width: 34.6%;
        padding: 0 0px 13px 7px;
        justify-content: space-between;
        width: 46.1%;

        .name {
          color: $teacher-primary;
          font-family: $app-primary-regular-font;
          font-size: 14px;
          letter-spacing: 0.33px;
          line-height: 19px;
          width: 53%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .info {
          display: flex;
          align-items: center;
          gap: 0 15px;
          padding: 2px 0 0;
          justify-content: flex-end;
          width: 47%;
        }

        .grade {
          color: $teacher-primary;
          font-family: $app-primary-regular-font;
          font-size: 11px;
          letter-spacing: 0.26px;
          line-height: 19px;
          width: 33%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: right;
        }
      }
    }
  }
}

.teacherProfile {
  input {
    opacity: 0;
  }
}

// .pdfBook {
//   width: 100%;
// }

// .pdfBook {
//   position: relative;

//   p.content1 {
//     position: absolute;
//     left: 32px;
//     top: 0;
//   }
// }

.deleteModal.ui.modal {
  width: 542.5px;
  border: 0.5px solid rgba(1, 62, 82, 0.38);
  background-color: #ffffff;
  box-shadow: 0 10px 47px 1px rgba(0, 0, 0, 0.31);

  .content {
    padding: 28px 24px 29px 35px;

    h3.ui.header {
      color: $teacher-primary;
      font-family: $app-primary-bold-font;
      font-size: 14px;
      letter-spacing: 0.33px;
      line-height: 25px;
      margin: 0 0 4px;
    }

    p {
      color: #000000;
      font-family: $app-primary-medium-font;
      font-size: 12px;
      letter-spacing: 0.28px;
      line-height: 19px;
      margin: 0;
    }

    .actionsBtn {
      margin: 12px 0 0;

      .ui.button {
        width: 140px;

        &.primaryBtn {
          margin: 0 0 0 14px;
        }
      }
    }
  }
}

.mailFontColor {
  color: $teacher-primary;
}

.special-label {
  cursor: default;
  pointer-events: none;
  text-decoration: none;
  color: white;
}

a.hidden {
  visibility: hidden;
}

h5.ui.header.forCommonTable {
  text-align: center;
  padding: 50px 0 !important;
  width: 100%;
}

.customPhoneInputField {
  &.error {
    label {
      color: #9f3a38;
    }

    .ui.input {
      input {
        background: #fff6f6;
        border-color: #e0b4b4;
        color: #9f3a38;

        &:focus {
          background: #fff6f6;
          border-color: #e0b4b4;
          color: #9f3a38;
        }
      }
    }
  }

  label {
    display: block;
    margin: 0 0 0.28571429rem 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.92857143em;
    font-weight: 700;
    text-transform: none;
  }

  .ui.input {
    width: 100%;
    margin: 0;

    input {
      width: 88% !important;
    }
  }

  .totalPricePerYear {
    .ui.input {
      width: 100%;
    }
  }
}

.customPhoneInput {
  display: flex;
  flex-direction: column-reverse;

  label {
    display: block;
    margin: 0 0 0.28571429rem 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.92857143em;
    font-weight: 700;
    text-transform: none;
  }

  .controlField {
    .ui.input {
      width: 100%;
    }

    &.error {
      +label {
        color: #9f3a38;
      }

      input {
        background: #fff6f6 !important;
        border-color: #e0b4b4 !important;
        color: #9f3a38 !important;

        &:focus {
          background: #fff6f6 !important;
          border-color: #e0b4b4 !important;
          color: #9f3a38 !important;
        }
      }
    }
  }
}

.mt-24 {
  margin-top: 24px;
}

.editBook {
  color: $primary-color;
  cursor: pointer !important;
}

.payMethod {
  .close.icon {
    position: absolute;
    top: 27%;
    right: 3px;
  }
}

.inputWithInput {
  position: relative;

  i.icon.dollar {
    position: absolute;
    left: 5px;
    top: 32px;
    z-index: 99;
    color: #999;
  }

  .ui.input {
    input {
      padding-left: 20px;
    }
  }
}

.customPaymentMessage {
  height: calc(100vh - 250px);
  display: flex;
  align-items: center;
  justify-content: center;
}

// .amountInput {
//   .ui.input {
//     &.error {
//       input {
//         background: #fff6f6;
//         border-color: #e0b4b4;
//         color: #9f3a38;
//       }
//     }
//   }
// }
.ui.fluid.left.labeled.input.form-control.priceAlignment.numericError+.srv-validation-message {
  bottom: initial;
}

.expiryDate {
  height: 49px;
  border: 1px solid rgba(0, 0, 0, 0.18);
  border-radius: 6px;
  background-color: #ffffff;
}

.opacity-1 {
  &.disabled {
    opacity: 1 !important;
  }
}

.booSearchIcon {
  position: relative;

  i.close.icon {
    position: absolute;
    top: 28%;
    right: 1%;
    font-size: 17px;
  }
}

.adminActionBtn {
  .deleteBtn.ui.button {
    background-color: $teacher-primary;
    color: $secondary-color;
  }

  .addBtn.ui.button {
    background-color: $teacher-secondary-dark-color;
    color: $secondary-color;
  }
}

.addStudentGrade {
  .ui.dropdown {
    .menu {
      max-height: 105px;
    }
  }
}

.resetPasswordModal {
  .field {
    &.error {
      .ui.input {
        input {
          background: #fff6f6;
          border-color: #e0b4b4;
          color: #9f3a38;

          &::placeholder {
            color: #9f3a38;
          }
        }
      }
    }
  }
}

.stl_03.stl_04 {
  height: 6.016666em !important;
}

.summaryBtn {
  width: 40%;

  .ui.button {
    &:first-child {
      margin-right: 10px;
    }
  }
}

a.ui.button.PrimaryOutlineBtn.pointer.Usermange {
  position: absolute;
  right: 79px;
  top: 97px;
  text-align: middle;
}

.engagementData {
  border: 1px solid #013e52;
  border-radius: 8px;
  padding: 13px;
}

.subscriptionStatus {
  span {
    font-family: $app-primary-bold-font;
    font-size: 18px;
    color: $teacher-primary;
  }

  .ui.buttons {
    .ui.button {
      &:first-child {
        border-right: 1px solid $teacher-primary;
      }

      &.active {
        background-color: $teacher-primary;
        color: $white-color;
      }
    }
  }
}

.actionHeaderCenter {
  text-align: center !important;
}

.actionCellCenter {
  text-align: center !important;
}

.plusSideIcon{
  margin-top: 17px !important;
}
//AleeLibrarySearch-Css//
.aleeLibrary {
  .swiper-container {
    .swiper-button-prev {
      color: $primary-color;
      left: 0;
    }

    .swiper-button-next {
      color: $primary-color;
      right: 0;
    }

    .swiper-wrapper {
      padding: 20px 0px 34px;
      .swiper-slide {
        margin-left: 100px;
        &.swiper-slide-active,
        &.swiper-slide-next,
        &.swiper-slide-prev {
          margin-left: 0;
        }
      }
    }

    .swiper-pagination-bullets {
      bottom: -5px;

      .swiper-pagination-bullet {
        &.swiper-pagination-bullet-active {
          background-color: $teacher-secondary-dark-color;
        }
      }
    }
  }

  .bookItem {
    display: flex;

    .bookImg {
      padding-left: 38px;

      a {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 170px;
        height: 280px;
        // box-shadow: -20px 30px 40px rgba(0, 0, 0, 0.3);
        box-shadow: -10px 10px 25px rgba(0, 0, 0, 0.2);
        min-width: 170px;
      }

      .ui.image {
        // box-shadow: -45px 45px 50px 0px rgba(168, 168, 168, 0.1), -45px 45px 50px 0px rgba(168, 168, 168, 1),
        //   -45px 45px 50px 0px rgba(168, 168, 168, 1);
        box-shadow: -20px 30px 40px rgba(0, 0, 0, 0.3);
        min-width: 170px;
        width: 170px;
        height: 280px;
        cursor: pointer;
        object-fit: contain;
        display: none;
      }

      a {
        position: relative;
        display: block;

        &:before {
          content: "";
          background-color: #9b9b9b;
          display: block;
          height: 100%;
          left: 6px;
          position: absolute;
          width: 1px;
          opacity: 0.6;
          z-index: 1;
        }
      }
    }

    .bookInfo {
      padding: 0 0 0 58px;

      h3.ui.header {
        color: $teacher-primary;
        font-family: $app-medium-font;
        font-size: 28px;
        line-height: 35px;
        margin: 0;
        cursor: pointer;
        padding: 23px 0 0;
        display: inline-block;
      }

      h5.ui.header {
        margin: 1px 0 10px;
        color: $teacher-primary;
        font-family: $app-primary-semibold-font;
        font-size: 13px;
        letter-spacing: 0.31px;
        line-height: 15px;
        min-height: 15px;
      }

      p {
        color: #000000;
        font-size: 12px;
        letter-spacing: 0.28px;
        line-height: 20px;
        max-width: 316px;
        min-height: 81px;
      }

      span.recommendedFor {
        color: $teacher-primary;
        font-size: 12px;
        letter-spacing: 0.28px;
        line-height: 15px;
        display: block;
        margin: 10px 0 23px;
      }

      .btns {
        display: flex;
        align-items: center;

        img {
          margin: 0px 0 0 12px;
          cursor: pointer;
        }
      }
    }
  }

  .filters {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
    padding: 0 0 0 20px;

    img.ui.image {
      max-width: 24px;
      margin: 0 10px 0 0;
    }

    .ui.dropdown {
      margin: 0 25px 0 0;
      // @media (max-width: 1300px) {
      //   margin: 0 10px 0 0;
      // }
      &.aleeLibraryAdmin {
          .menu {
            max-height: calc(100vh - 400px);
            .ui.list {
              max-height: calc(100vh - 400px);
              overflow: auto;
              &::-webkit-scrollbar-track {
                // -webkit-box-shadow: inset 0 0 5px rgba(1, 62, 82, 0.38);
                border-radius: 10px;
                background-color: transparent;
              }
      
              &::-webkit-scrollbar {
                width: 6px;
                background-color: transparent;
              }
      
              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #bf964a;
                width: 6px;
      
                &:hover {
                  background-color: #bf964a;
                }
              }
            }
          }
      }
      &.standard {
        .menu {
          max-height: initial;
          overflow: visible !important;
          border-radius: 0 !important;
          box-shadow: none;
          min-width: auto;
          max-width: 100px;

          .ui.list {
            .item {
              padding: 0.78571429rem 1.14285714rem !important;
              cursor: pointer;
              font-size: 12px;
              color: #013e52;
              display: flex;
              gap: 10px;
            }
          }

          .firstMenu {
            min-width: 100px;
          }

          .secondMenu {
            position: absolute;
            left: 98px;
            top: -1px;
            border: 1px solid rgba(1, 62, 82, 0.38);
            border-radius: 5px;
            background-color: #fffcf6;
            min-width: 150px;
            border-radius: 0;
          }

          .thirdMenu {
            position: absolute;
            left: 148px;
            top: -1px;
            border: 1px solid rgba(1, 62, 82, 0.38);
            border-radius: 5px;
            background-color: #fffcf6;
            min-width: 120px;
            border-radius: 0;
          }

          .thirdMenued {
            position: absolute;
            left: 148px;
            top: -1px;
            border: 1px solid rgba(1, 62, 82, 0.38);
            border-radius: 5px;
            background-color: #fffcf6;
            width: 150px;
            border-radius: 0;
          }
        }
      }

      .menu {
        border: 1px solid rgba(1, 62, 82, 0.38);
        border-radius: 5px;
        background-color: #fffcf6;
        box-shadow: 0 23px 45px 11px rgba(0, 0, 0, 0.1);
        max-height: 200px;
        overflow: auto!important;

        &::-webkit-scrollbar-track {
          // -webkit-box-shadow: inset 0 0 5px rgba(1, 62, 82, 0.38);
          border-radius: 10px;
          background-color: transparent;
        }

        &::-webkit-scrollbar {
          width: 6px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #bf964a;
          width: 6px;

          &:hover {
            background-color: #bf964a;
          }
        }

        &::after {
          display: none;
        }
       
        .item {
          .ui.checkbox {
            label {
              font-size: 12px;
              color: $teacher-primary;
            }
          }
        }

        &.transition {
          opacity: 0 !important;

          &.visible {
            opacity: 1 !important;
          }
        }

        &:hover {
          opacity: 1 !important;
        }
      }

      .text {
        color: #bf964a;
        font-family: $app-primary-medium-font;
        font-size: 12px;
        letter-spacing: 0.26px;
        line-height: 15px;

        // @media (max-width: 1300px) {
        //   font-size: 10px;
        // }
      }

      i.icon {
        font-size: 10px;
        margin: 0 0 0 5px;
        color: #bf964a;
        opacity: 1 !important;
      }
    }

    .ui.input {
      padding: 0;
      max-width: 110px;

      input {
        border: none;
        font-family: $app-primary-medium-font;
        color: #bf964a;
        background-color: transparent;
        font-size: 11px;

        // @media (max-width: 1300px) {
        //   font-size: 10px;
        // }

        &::placeholder {
          color: #bf964a;
          font-family: $app-primary-medium-font;
          font-size: 11px;

          // @media (max-width: 1300px) {
          //   font-size: 10px;
          // }
        }
      }

      i.icon {
        color: #bf964a;
        opacity: 1 !important;
        font-size: 11px;

        @media (max-width: 1300px) {
          font-size: 10px;
        }
      }
    }

    .filterIcon {
      color: $teacher-secondary-dark-color;
      opacity: 1;
      margin: -6px 10px 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.showAdminCheckbox{
  display: flex;
    padding: 40px 0 0;
    gap: 20px;
}
.summaryText {
  white-space: break-spaces;
}
.DeleteStudent {
  position: absolute;
  right: 50px;
  top: 22px;
}

.password-criteria {
  list-style: none;
  padding-left: 0;
}

.password-criteria li {
  margin-bottom: 5px;
}

.password-criteria li.valid:before {
  content: '✓';
  color: green;
  margin-right: 10px;
}

.password-criteria li.invalid:before {
  content: '𐄂';
  color: red;
  margin-right: 10px;
}

.plan-selection-button {
  background-color: #003e52;
  padding:20px;
  color:#FFF;
  font-weight:bold;
}